<template>
  <div>
    <div id="mapContainer" />
    <!-- 大华视频插件 -->
    <VideoItemDialogDH
      :videoDialogFlag="videoDialogFlag"
      :videoDialogData="videoData"
      @closeDialog="closeDialog" />
    <!-- flv播放方式 -->
    <VideoItemDialog
      :videoDialogFlag="videoDialogFlag2"
      :videoDialogData="videoData2"
      @closeDialog="closeDialog2" />
    <!-- start接口播放方式 -->
    <VideoItemDialogS
      :videoDialogFlag="videoDialogFlag3"
      :videoDialogData="videoData3"
      @closeDialog="closeDialog3" />
    <!-- 地图图例 -->
    <div class="map-legend">
      <el-popover
        v-for="(item, index) in LegendData"
        :key="`legend${index}`"
        class="item"
        effect="dark"
        trigger="manual"
        placement="left"
        :value="popVisible(item.flag)">
        <div>{{ item.name }} 数量：{{ item.total }}</div>
        <div
          v-show="
            item.path.includes($route.path) ||
            (item.name == '清空图层' && ['/block', '/water', '/center'].includes($route.path))
          "
          @click="mapLegendFun(item)"
          slot="reference">
          <SvgIcon
            :title="item.name"
            :name="
              mapLegendArr.includes(item.flag)
                ? `map-icon-active${item.flag}`
                : `map-icon${item.flag}`
            " />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import {
  swDeviceWaterList,
  xzGreenWaysList,
  xzParksList,
  xzPointsListAll,
  xzRiverCoursesList
} from '@/api/base.js'
import CAR from '@/assets/svg/car.svg'
import PUBLIC from '@/assets/svg/center/center1.svg'
import TENEMENT from '@/assets/svg/center/center2.svg'
import FIRM from '@/assets/svg/center/center3.svg'
import COMPLEX from '@/assets/svg/center/center4.svg'
import LITERARY from '@/assets/svg/center/center5.svg'
import SCHOOL from '@/assets/svg/center/center6.svg'
import AGE from '@/assets/svg/center/center7.svg'
import TREATMENT from '@/assets/svg/center/center8.svg'
import ELECARD_ICON_OUTLINE from '@/assets/svg/marker-grey.svg'
import ELECARD_ICON from '@/assets/svg/marker.png'
import VIDEO from '@/assets/svg/videoMarker.svg'
import VIDEOS from '@/assets/svg/videoMarkerS.svg'
import WATERM from '@/assets/svg/water/waterM.svg'
import WATERU from '@/assets/svg/water/waterU.svg'
import WATERX from '@/assets/svg/water/waterX.svg'
import WATERY from '@/assets/svg/water/waterY.svg'
import VideoItemDialogDH from '@/components/DHVideo/VideoItemDialog.vue'
import VideoItemDialogS from '@/components/HKStartVideo/VideoItem.vue'
import SvgIcon from '@/components/SvgIcon/index.vue'
import VideoItemDialog from '@/components/VideoItemDialog.vue'
import { mapGetters } from 'vuex'
import { POLYGONS } from '../Map/data'
const MAPLEGEND = [
  // { name: '河道', path: ['/water'], flag: 1, id: 'i-river' },
  { name: '公园', path: ['/block'], flag: 2, id: 'i-park' },
  // { name: '口袋公园', path: ['/block'], flag: 3, id: 'i-pocket-park' },
  { name: '绿道', path: ['/block'], flag: 4, id: 'i-green' },
  { name: '河道水质', path: ['/water'], flag: 5, id: 'i-water-m', icon: WATERM },
  { name: '河道水位', path: ['/water'], flag: 6, id: 'i-water-u', icon: WATERU },
  { name: '窨井', path: ['/water'], flag: 7, id: 'i-water-y', icon: WATERY },
  { name: '下立交', path: ['/water'], flag: 8, id: 'i-water-x', icon: WATERX },
  // { name: '摄像头', path: ['/water'], flag: 9, id: 'i-video', icon: WATERV },
  { name: '公共机构', path: ['/center', '/block'], flag: 10, id: 'i-public', icon: PUBLIC },
  { name: '楼宇', path: ['/center', '/block'], flag: 11, id: 'i-tenement', icon: TENEMENT },
  { name: '企业', path: ['/center', '/block'], flag: 12, id: 'i-firm', icon: FIRM },
  { name: '商业综合体', path: ['/center', '/block'], flag: 13, id: 'i-complex', icon: COMPLEX },
  { name: '文体', path: ['/center', '/block'], flag: 14, id: 'i-literary', icon: LITERARY },
  { name: '学校', path: ['/center'], flag: 15, id: 'i-school', icon: SCHOOL },
  { name: '养老', path: ['/center', '/block'], flag: 16, id: 'i-age', icon: AGE },
  { name: '医疗', path: ['/center'], flag: 17, id: 'i-treatment', icon: TREATMENT },
  { name: '清空图层', path: '', flag: 18, id: 'i-clear' }
]
export default {
  components: { VideoItemDialogDH, VideoItemDialog, VideoItemDialogS, SvgIcon },
  name: 'JxMap',
  data() {
    return {
      MAPLEGEND,
      videoDialogFlag: false,
      videoData: {},
      videoDialogFlag2: false,
      videoData2: {},
      videoDialogFlag3: false,
      videoData3: {},
      mapLegendArr: [],
      activeName: '',
      activeFlag: null,
      activeId: '',
      activeIdArr: [],
      activeIdPointArr: [],
      LegendData: JSON.parse(JSON.stringify(MAPLEGEND))
    }
  },
  mounted() {
    window.vue = this
    window.jMap = null
    window.jmapQuery = null
    this.initMap()
  },
  computed: {
    ...mapGetters(['mapPointData', 'mapMarkers'])
  },
  watch: {
    mapMarkers() {
      window.jMap.Locate.clearLocate()
      let detail = this.$store.state.deviceMarkers
      detail.forEach((ele, idx) => {
        let position = { x: ele[0], y: ele[1] }
        let image = {
          url: VIDEO, //'https://img2.baidu.com/it/u=325688235,2500088637&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=727',
          width: 20,
          height: 20,
          pixelOffset: [0, 0]
        }
        let text = ele.name
        let iconName = `point${Math.random().toString(36).substring(2)}`
        this.activeIdPointArr.push(iconName)
        this.setPoint(iconName, {}, position, false, image, text)
      })
    },
    mapPointData(val) {
      const data = JSON.parse(val)
      const { name } = data
      if (name == 'patrol') {
        window.jMap.Locate.clearLocate()
        let patrolArr = []
        let _that = this
        data.detailData.forEach((ele, idx) => {
          let wgsPoint = window.CoordUtil.gcj02towgs84(Number(ele.longitude), Number(ele.latitude))
          let position = { x: wgsPoint[0], y: wgsPoint[1] }
          patrolArr.push(position)
        })
        let style = {
          width: 5
        }
        _that.setLine(
          `patrolLine${Math.random().toString(36).substring(2)}`,
          {},
          patrolArr,
          false,
          style
        )
      } else if (name == 'river') {
        let _that = this
        data.detailData.forEach((ele, idx) => {
          if (ele.info) {
            let arr = JSON.parse(ele.info)
            let patrolArr = arr.features[0].geometry.coordinates.map((d) => {
              return {
                x: d[0],
                y: d[1]
              }
            })
            let style
            let idName
            if (data.flag == '绿道') {
              style = {
                width: 12,
                color: 'rgb(50,205,50,0.9)'
              }
              idName = `greenLine${Math.random().toString(36).substring(2)}`
            } else {
              style = {
                width: 8,
                color: 'rgb(0,255,255,0.9)'
              }
              idName = `blueLine${Math.random().toString(36).substring(2)}`
            }
            _that.setLine(idName, {}, patrolArr, false, style)
          }
        })
      } else if (name == 'area') {
        let _that = this
        data.detailData.forEach((ele, idx) => {
          if (ele.info) {
            let arr = JSON.parse(ele.info)
            let patrolArr = arr.features[0].geometry.coordinates[0].map((d) => {
              // let wgsPoint = window.CoordUtil.gcj02towgs84(Number(d[0]), Number(d[1]))
              // let position = { x: wgsPoint[0], y: wgsPoint[1] }
              return {
                x: d[0],
                y: d[1]
              }
            })
            let style = {
              color: 'rgb(144,238,144,0.6)',
              transparency: 0.6,
              outline: true,
              outlineColor: 'rgb(144,238,144,1)',
              perPositionHeight: false
            }
            let label = {
              show: true,
              text: ele.name, //   标注内容
              font: '16px Helvetica',
              color: 'rgb(255,255,255,0.9)', //   字体颜色
              pixelOffset: [0, -80], //    偏移量
              showBackground: true,
              backgroundColor: 'rgb(0,0,0,0.9)'
            }
            _that.setArea({}, patrolArr, false, style, label)
          }
        })
      } else if (name == 'videoPoint') {
        window.jMap.Locate.clearLocate(this.activeIdPointArr)
        if (data.flag && data.flag == 'multiple') {
          this.videoListShow = true
        } else {
          this.videoListShow = false
        }
        //视频设备撒点
        let _that = this
        data.detailData.forEach((ele, idx) => {
          let wgsPoint = window.CoordUtil.gcj02towgs84(Number(ele.longitude), Number(ele.latitude))
          let position = { x: wgsPoint[0], y: wgsPoint[1] }
          let image = {
            url:
              data.type && data.type == '车辆数量'
                ? CAR
                : data.type && data.type == '考勤数量'
                ? ELECARD_ICON
                : VIDEO, //'https://img2.baidu.com/it/u=325688235,2500088637&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=727',
            width: 20,
            height:
              (data.type && data.type == '车辆数量') || (data.type && data.type == '考勤数量')
                ? 31
                : 20,
            pixelOffset: [0, 0]
          }
          let text = ele.name
          let iconName = `point${Math.random().toString(36).substring(2)}`
          this.activeIdPointArr.push(iconName)
          this.setPoint(
            iconName,
            () => {
              if (data.type && data.type == '鹰眼') {
                if (ele.channelId) {
                  _that.videoDialogFlag2 = true
                  _that.videoData2 = ele
                } else {
                  _that.videoDialogFlag3 = true
                  _that.videoData3 = ele
                }
              } else if (data.type && data.type == '社区') {
                _that.videoDialogFlag3 = true
                _that.videoData3 = ele
              } else {
                _that.videoDialogFlag = true
                _that.videoData = ele
              }
            },
            position,
            false,
            image,
            text
          )
        })
      } else if (name == 'videoSelectPoint') {
        window.jMap.Locate.clearLocate()
        //视频设备撒点
        let { longitude, latitude, name, id } = data.detailData
        let wgsPoint = window.CoordUtil.gcj02towgs84(Number(longitude), Number(latitude))
        let position = { x: wgsPoint[0], y: wgsPoint[1] }
        let image = {
          url: VIDEOS, //'https://img2.baidu.com/it/u=325688235,2500088637&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=727',
          width: 20,
          height:
            (data.type && data.type == '车辆数量') || (data.type && data.type == '考勤数量')
              ? 31
              : 20,
          pixelOffset: [0, 0]
        }
        let text = name
        let iconName = `point${Math.random().toString(36).substring(2)}`
        this.activeIdPointArr.push(iconName)
        this.setPoint(
          iconName,
          () => {
            this.videoDialogFlag = true
            this.videoData = data.detailData
          },
          position,
          false,
          image,
          text
        )
      } else if (name == 'commonPoint') {
        window.jMap.Locate.clearLocate(this.activeIdPointArr)
        //通用撒点
        data.detailData.forEach((ele, idx) => {
          let wgsPoint = window.CoordUtil.gcj02towgs84(Number(ele.longitude), Number(ele.latitude))
          let position = { x: wgsPoint[0], y: wgsPoint[1] }
          let image = {
            url: ele.isOnline == 1 ? ELECARD_ICON : ELECARD_ICON_OUTLINE, //'https://img2.baidu.com/it/u=325688235,2500088637&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=727',
            width: 20,
            height: 31,
            pixelOffset: [0, 0]
          }
          let text = ele.name
          let iconName = `point${Math.random().toString(36).substring(2)}`
          this.activeIdPointArr.push(iconName)
          this.setPoint(iconName, {}, position, false, image, text)
        })
        // this.commonMarkers = commonMarkers
      } else if (name == 'eyeVideoList') {
        //鹰眼列表
        if (data.detailData.channelId) {
          this.videoDialogFlag2 = true
          this.videoData2 = data.detailData
        } else {
          this.videoDialogFlag3 = true
          this.videoData3 = data.detailData
        }
      }
    },
    $route(to, from) {
      window.jMap.Locate.clearLocate()
      this.mapLegendArr = []
      this.setCommonArea()
      if (to.path == '/water') {
        this.xzRiverCoursesListFun()
      }
    }
  },
  /**
   * 地图交互
   * @param {Array | Object} position 坐标 {x: 121, y: 31}
   * @param {Boolean} isZoom 是否缩进
   *  @param {Function} callback 回调方法
   */
  methods: {
    popVisible(flag) {
      return this.mapLegendArr.includes(flag)
    },
    getTotal(num) {
      let legend = this.LegendData.find((d) => d.flag == this.activeFlag)
      let idx = this.LegendData.indexOf(legend)
      this.$set(this.LegendData[idx], 'total', num)
    },
    setArea(idName, callback, position, isZoom = false, style, label) {
      window.jMap.Locate.regionLocate(
        {
          id: idName,
          attributes: {
            name: '测试区域分布'
          },
          position
        },
        {
          isZoom,
          label,
          style,
          click: callback
        }
      )
    },
    setLine(id, callback, position, isZoom = false, style) {
      window.jMap.Locate.trackLocate(
        {
          id: id,
          attributes: {
            name: '测试轨迹分布'
          },
          position
        },
        {
          isZoom,
          style,
          click: callback
        }
      )
    },
    setPoint(iconName, callback, position, isZoom = false, image, text) {
      window.jMap.Locate.pointLocate(
        [
          {
            id: iconName, // `point${Math.random().toString(36).substring(2)}`,
            position: position,
            attributes: {
              name: '闵行区大数据中心',
              description: '测试自定义字段信息'
            },
            label: {
              text: text,
              font: '14px Helvetica',
              color: 'rgb(255, 255, 255, 1)',
              pixelOffset:
                text == '闵行区政府'
                  ? [0, -70]
                  : text == '闵行区政务服务中心'
                  ? [0, -90]
                  : [0, -50],
              showBackground: true,
              backgroundColor: 'rgb(9, 73, 158,0.9)'
            },
            image: image
          }
        ],
        {
          isZoom,
          style: {
            cursor: 'pointer'
          },
          scaleByDistance: {
            minScaling: 1,
            maxScaling: 2,
            minDistance: 50,
            maxDistance: 12000000
          },
          clampToHeight: true,
          click: callback
        }
      )
    },
    initMap() {
      window.jMap = new JMap()
      var map_params = {
        camera: {
          x: 121.373372, //121.4175597,
          y: 31.10877, //31.119248,
          radius: 2000.0,
          offset: { heading: -4, pitch: -45, range: 0 }
        },
        area: '1207',
        map_complete_callback: function () {
          window.jmapQuery = new JMapQuery(window.jMap)
        }
      }
      window.jMap.createMap(
        'mapContainer',
        '04f1914ed0f5cde8df2cc606bebdbb3244fb790f631346eac0dd8e5808d62957f08d27f476769424af38ca58649406d0d7f8408eba4bdd17b2169c4a0abb82879ac4c02121c0917c48924bc93ecd4c4935c4351b71196c6a671349967dc50f7ca511d3afee87a74e035ef56ae03e1abaf00521eadab7dbdfdda1442d3007c3fd6db66ae1e4a4adaf94851ab88207d7101d11b90d727ca960',
        window.location.origin,
        map_params,
        {}
      )
      // window.jMap.switchBaseLayer('light')
      // window.jMap.switchBuildingModel('light')
      this.setCommonArea()
      if (this.$route.path == '/water') {
        this.xzRiverCoursesListFun()
      }
    },
    setCommonArea() {
      POLYGONS.forEach((ele) => {
        let idName = `comm-area${Math.random().toString(36).substring(2)}`
        let style = {
          color: ele.color,
          transparency: 0.2,
          outline: true,
          outlineColor: ele.color,
          perPositionHeight: false
        }
        let label = {
          show: false
        }
        let patrolArr = ele.path.map((d) => {
          let wgsPoint = window.CoordUtil.gcj02towgs84(Number(d[0]), Number(d[1]))
          return {
            x: wgsPoint[0],
            y: wgsPoint[1]
          }
        })
        this.setArea(idName, {}, patrolArr, false, style, label)
      })
    },
    closeDialog() {
      this.videoDialogFlag = false
      this.videoData = {}
    },
    closeDialog2() {
      this.videoDialogFlag2 = false
      this.videoData2 = {}
    },
    closeDialog3() {
      this.videoDialogFlag3 = false
      this.videoData3 = {}
    },
    mapLegendFun({ flag, name, id, path, icon }) {
      this.activeName = name
      this.activeFlag = flag
      if (name == '清空图层') {
        window.jMap.Locate.clearLocate()
        this.mapLegendArr = []
        this.setCommonArea()
        if (this.$route.path == '/water') {
          this.xzRiverCoursesListFun()
        }
        return
      }
      if (this.mapLegendArr.includes(flag)) {
        //清空
        let num = this.mapLegendArr.indexOf(flag)
        this.mapLegendArr.splice(num, 1)
        let idArr = this.activeIdArr.filter((d) => d.indexOf(id) !== -1)
        window.jMap.Locate.clearLocate(idArr)
      } else {
        //添加
        this.mapLegendArr.push(flag)
        if (name == '河道') {
          this.xzRiverCoursesListFun()
        } else if (name == '公园' || name == '口袋公园') {
          this.xzParksListFun(name)
        } else if (name == '绿道') {
          this.xzGreenWaysListFun()
        } else if (path.includes('/center')) {
          this.commFun(icon, id, name)
        } else if (path == '/water') {
          this.riverCommFun(icon, id, name)
        }
      }
    },
    //河道列表
    async xzRiverCoursesListFun() {
      let res = await xzRiverCoursesList({
        pageNum: 1,
        pageSize: 100
      })
      if (res.result == 0) {
        let { records } = res.data
        const TYPE = ['镇级', '市级', '区级']
        let recordsArr = records.filter((d) => TYPE.includes(d.type))
        this.getTotal(res.data.total)
        this.commLine(JSON.stringify(recordsArr))
        // let a = {}
        // a.name = 'river'
        // a.detailData = res.data.records
        // a.date = new Date().valueOf()
        // this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
      }
    },
    //绿道列表
    async xzGreenWaysListFun() {
      let res = await xzGreenWaysList({ pageNum: 1, pageSize: 100 })
      if (res.result == 0) {
        let { records } = res.data
        this.getTotal(res.data.total)
        this.commLine(JSON.stringify(records), '绿道')
        // let a = {}
        // a.name = 'river'
        // a.flag = '绿道'
        // a.detailData = res.data.records
        // a.date = new Date().valueOf()
        // this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
      }
    },
    //公园列表
    async xzParksListFun(name) {
      let res = await xzParksList({ pageNum: 1, pageSize: 100, type: name })
      if (res.result == 0) {
        let { records } = res.data
        if (name == '公园') {
          this.commArea(JSON.stringify(records), 'rgb(144,238,144,0.6)', 'i-park')
        } else if (name == '口袋公园') {
          this.commArea(JSON.stringify(records), 'rgba(0, 255, 201, 0.7)', 'i-pocket-park')
        }
        this.getTotal(res.data.total)

        // let a = {}
        // a.name = 'area'
        // a.detailData = res.data.records
        // a.date = new Date().valueOf()
        // this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
      }
    },
    //城运体征--图例
    async commFun(icon, id, name) {
      let { data } = await xzPointsListAll({ committee: name })
      this.getTotal(data.length)
      //通用撒点
      data.forEach((ele, idx) => {
        let wgsPoint = window.CoordUtil.gcj02towgs84(Number(ele.longitude), Number(ele.latitude))
        let position = { x: wgsPoint[0], y: wgsPoint[1] }
        let image = {
          url: icon, //'https://img2.baidu.com/it/u=325688235,2500088637&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=727',
          width: 20,
          height: 33,
          pixelOffset: [0, 0]
        }
        let text = ele.name
        let iconName = `${id}${Math.random().toString(36).substring(2)}`
        this.activeIdArr.push(iconName)
        this.setPoint(iconName, {}, position, false, image, text)
      })
    },
    //首页--图例--画线
    commLine(records, flag) {
      let recordsArr = JSON.parse(records)
      recordsArr.forEach((ele, idx) => {
        if (ele.info) {
          let arr = JSON.parse(ele.info)
          let dataArr = []
          if (arr?.constructor === Object) {
            dataArr.push(arr)
          } else {
            dataArr = arr
          }
          dataArr.forEach((ele2) => {
            ele2.features.forEach((ele3) => {
              let patrolArr = ele3.geometry.coordinates.map((d) => {
                // let wgsPoint = window.CoordUtil.gcj02towgs84(Number(d[0]), Number(d[1]))
                return {
                  x: d[0],
                  y: d[1]
                }
              })
              let style
              let idName
              if (flag && flag == '绿道') {
                style = {
                  width: 12,
                  color: 'rgb(50,205,50,0.9)'
                }
                idName = `i-green${Math.random().toString(36).substring(2)}`
              } else {
                style = {
                  width: 10,
                  color:
                    ele.type == '镇级'
                      ? 'rgba(64,224,208, 1)'
                      : ele.type == '市级'
                      ? 'rgba(5, 176, 255, 1)'
                      : ele.type == '区级'
                      ? 'rgba(5, 176, 255, 1)'
                      : 'rgba(64,224,208, 1)'
                }
                idName = `i-river${Math.random().toString(36).substring(2)}`
              }
              this.activeIdArr.push(idName)
              this.setLine(idName, {}, patrolArr, false, style)
            })
          })
        }
      })
    },
    //图例--画区域
    commArea(records, color, id) {
      let recordsArr = JSON.parse(records)
      recordsArr.forEach((ele, idx) => {
        if (ele.info) {
          let arr = JSON.parse(ele.info)
          let patrolArr = arr.features[0].geometry.coordinates[0].map((d) => {
            // let wgsPoint = window.CoordUtil.gcj02towgs84(Number(d[0]), Number(d[1]))
            return {
              x: d[0],
              y: d[1]
            }
          })
          let style = {
            color: color,
            transparency: 0.6,
            outline: true,
            outlineColor: 'rgb(144,238,144,1)',
            perPositionHeight: false
          }
          let label = {
            show: true,
            text: ele.name, //   标注内容
            font: '16px Helvetica',
            color: 'rgb(255,255,255,0.9)', //   字体颜色
            pixelOffset: [0, -80], //    偏移量
            showBackground: true,
            backgroundColor: 'rgb(0,0,0,0.9)'
          }
          let idName = `${id}${Math.random().toString(36).substring(2)}`
          this.activeIdArr.push(idName)
          this.setArea(idName, {}, patrolArr, false, style, label)
        }
      })
    },
    //水务--图例
    async riverCommFun(icon, id, name) {
      let { data } = await swDeviceWaterList({ deviceType: name })
      this.getTotal(data.length)
      //通用撒点
      data.forEach((ele, idx) => {
        let wgsPoint = window.CoordUtil.gcj02towgs84(Number(ele.x), Number(ele.y))
        let position = { x: wgsPoint[0], y: wgsPoint[1] }
        let image = {
          url: icon, //'https://img2.baidu.com/it/u=325688235,2500088637&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=727',
          width: 20,
          height: 33,
          pixelOffset: [0, 0]
        }
        let text = ele.deviceName
        let iconName = `${id}${Math.random().toString(36).substring(2)}`
        this.activeIdArr.push(iconName)
        this.setPoint(iconName, {}, position, false, image, text)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.map-legend {
  position: fixed;
  bottom: 155px;
  right: 1110px;
  width: 52px;
}
</style>
