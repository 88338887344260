import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/CommonLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login/loginIndex'),
    hidden: true
  },
  {
    path: '/center',
    redirect: '/block',
    component: Layout,
    children: [
      {
        path: '/center',
        name: 'DataCenter',
        components: {
          left: () => import('@/views/Center/CenterViewLeft.vue'),
          right: () => import('@/views/Center/CenterViewRight.vue')
        }
      },
      {
        path: '/block',
        name: 'BlockView',
        components: {
          left: () => import('@/views/Block/BlockViewLeft.vue'),
          right: () => import('@/views/Block/BlockViewRight.vue')
        }
      },
      {
        path: '/community',
        name: 'CommunityView',
        components: {
          left: () => import('@/views/Community/CommViewLeft.vue'),
          right: () => import('@/views/Community/CommViewRight.vue')
        }
      },
      {
        path: '/monitor',
        name: 'MonitorView',
        components: {
          left: () => import('@/views/Monitor/MonitorViewLeft.vue'),
          right: () => import('@/views/Monitor/MonitorViewRight.vue')
        }
      },
      {
        path: '/water',
        name: 'WaterView',
        components: {
          left: () => import('@/views/Water/WaterViewLeft.vue'),
          right: () => import('@/views/Water/WaterViewRight.vue')
        }
      },
      {
        path: '/attendance',
        name: 'AttendanceView',
        components: {
          left: () => import('@/views/Attendance/AttendanceViewRight.vue'),
          right: () => import('@/views/Attendance/AttendanceViewLeft.vue')
        }
      }
    ]
  },
  {
    path: '/campus',
    name: 'CampusView',
    component: () => import('@/views/Campus/CampusView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/xinzhuang-hairtail', // process.env.BASE_URL,
  routes
})

export default router
