<template>
  <div class="video-card full-bg">
    <el-dialog
      :title="titles"
      :visible.sync="videoDialog"
      width="1200px"
      custom-class="video-dialog"
      :show-close="true"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="false"
      :before-close="destroyDHVideo">
      <!-- destroy-on-close -->
      <div class="edge" />
      <div class="edge right-top" />
      <div class="edge left-bottom" />
      <div class="edge right-bottom" />
      <div style="width: 100%; height: 774px; position: relative">
        <div class="actionsNew">
          <el-tooltip effect="dark" content="截屏">
            <i @click="screenShot" class="el-icon-scissors" />
          </el-tooltip>
          <el-tooltip effect="dark" content="历史记录">
            <i @click="screenHistoryDialog = true" class="el-icon-folder-opened" />
          </el-tooltip>
        </div>
        <!-- <el-container
        v-loading="loadings"
        element-loading-custom-class="loading_color"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        element-loading-spinner="el-icon-loading"
        style="width: 100%; height: 749px"> -->
        <!-- <div
          class="video-box"
          style="width: 100%; height: 749px; background: yellow; position: absolute; top: 0"> -->
        <!-- <div id="DHVideoPlayer" style="width: 100%; height: 650px; object-fit: fill"></div> -->
        <div
          :id="radomID"
          :key="radomID"
          style="width: 100%; height: 650px; object-fit: fill"></div>

        <!-- </div> -->
        <!-- </el-container> -->
        <div class="video-dialog__btm_new full-bg">
          <div class="yun-tai">
            <svg-icon icon-class="video-turn" style="width: 165px" />
            <!-- 1=上，2=下，3=左，4=右，5=左上，6=左下，7=右上，8=右下 -->
            <div class="top pos" @click="videoTurn('1')"></div>
            <div class="bottom pos" @click="videoTurn('2')"></div>
            <div class="left pos" @click="videoTurn('3')"></div>
            <div class="right pos" @click="videoTurn('4')"></div>
            <div class="top-left pos" @click="videoTurn('5')"></div>
            <div class="bottom-left pos" @click="videoTurn('6')"></div>
            <div class="top-right pos" @click="videoTurn('7')"></div>
            <div class="bottom-right pos" @click="videoTurn('8')"></div>
          </div>
          <div
            v-for="item in LEFT_BTNS"
            :key="`lybtn${item.name}`"
            class="item left"
            @click="changeButton(item)">
            <svg-icon :icon-class="item.icon" />
            <label>{{ item.name }}</label>
          </div>
          <div class="animate-button" @click="startTalk()">
            <svg-icon :icon-class="activeIcon" />
          </div>
          <div
            v-for="item in RIGHT_BTNS"
            :key="`rybtn${item.name}`"
            class="item"
            @click="btnFunc(item)">
            <svg-icon :icon-class="item.icon" />
            <label>{{ item.name }}</label>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dictDialog"
      width="456px"
      custom-class="tags-dialog common-dialog"
      append-to-body>
      <div class="edge" />
      <div class="edge right-top" />
      <div class="edge left-bottom" />
      <div class="edge right-bottom" />
      <div slot="title" class="common-dialog__header">
        <LadderAnimate />
        <h3>自动播报词条库</h3>
        <LadderAnimate side="right" />
      </div>
      <h3 class="hint normal-text">
        可播报字段（仅支持单选）
        <i class="el-icon-edit cursor fr"></i>
      </h3>
      <div class="tags-wrap">
        <el-tag
          v-for="tag in DEFAULT_TAGS"
          :key="tag"
          :class="{ active: tag === activeTag }"
          @click="activeTag = tag">
          {{ tag }}
        </el-tag>
      </div>
      <div slot="footer" class="common-dialog__footer">
        <el-button @click="dictDialog = false" round>取消</el-button>
        <el-button @click="dictDialog = false" type="primary" round>确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="截图记录"
      :visible.sync="screenHistoryDialog"
      width="762px"
      custom-class="screen-record-diaolog common-dialog"
      :show-close="true"
      append-to-body>
      <div class="edge" />
      <div class="edge right-top" />
      <div class="edge left-bottom" />
      <div class="edge right-bottom" />
      <div slot="title" class="common-dialog__header">
        <LadderAnimate />
        <h3>截图记录</h3>
        <LadderAnimate side="right" />
      </div>
      <div class="time-select">
        <span>时间段:</span>
        <el-date-picker
          v-model="range"
          type="daterange"
          clearable="false"
          start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期" />
      </div>
      <div class="img-wrap">
        <div v-for="idx in 10" :key="`imgsc${idx}`" class="img-item">
          <img :src="require('@/assets/image/video.png')" />
          <span>2023-01-14 12:23:45</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import LadderAnimate from '@/components/AnimationCollection/LadderAnimate.vue'
import { deviceDHDetail, realTimeAddress, startTalk } from '@/api/alarm'
const LEFT_BTNS = [
  {
    name: '关闭',
    icon: 'mic-mute'
  },
  {
    name: '喊话',
    icon: 'mic'
  }
]
const RIGHT_BTNS = [
  {
    name: '播报',
    icon: 'speaker'
  },
  {
    name: '词条库',
    icon: 'note'
  },
  {
    name: '视角缩小',
    icon: 'video-small'
  },
  {
    name: '视角放大',
    icon: 'video-big'
  }
]
const DEFAULT_TAGS = [
  '请将非机动车听如规定区域内',
  '您已涉嫌占道经营',
  '禁止停车',
  '您已涉嫌跨门经营'
]
var videoPlayer = null
var beforeType = '0'
var currentIndex = 0
var divisionNum = 4
var downloadState = false
export default {
  components: {
    LadderAnimate
  },
  props: {
    videoDialogFlag: {
      type: Boolean,
      default: false
    },
    videoDialogData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      videoId: `videoId${Math.random().toString(36).substr(2)}`,
      range: [],
      activeTag: '禁止停车',
      DEFAULT_TAGS,
      LEFT_BTNS,
      RIGHT_BTNS,
      videoDialog: false,
      checked: false,
      dictDialog: false,
      activeIcon: 'mic-mute',
      isShooting: false,
      screenHistoryDialog: false,
      loading: true,
      myVideo1: null,
      myVideo2: null,
      myVideo3: null,
      myVideo4: null,
      myVideo5: null,
      myVideo6: null,
      titles: '北门路东侧中国电信华为',
      myVideoD: null,
      loadings: true,
      channelId: '',
      DHVideoUrl: null,
      DHTalkUrl: null,
      radomID: ''
    }
  },
  computed: {
    iconClass() {
      return this.checked ? 'checked' : 'uncheck'
    }
  },
  watch: {
    videoDialogFlag: {
      handler(newVal) {
        if (newVal) {
          this.zoomFunc()
        }
      },
      immediate: true,
      deep: true
    },
    videoDialog(val) {
      if (!val) {
        //关闭
        console.log('关闭')
        this.$emit('closeDialog', false)
      }
    }
  },
  created() {
    this.radomID = `bar${Math.random() * 10 + 4}`
  },
  mounted() {},
  methods: {
    destroyDHVideo() {
      console.log('销毁')
      videoPlayer.destroy()
      videoPlayer = null
      this.videoDialog = false
    },
    screenShot() {
      this.isShooting = true
      setTimeout(() => {
        this.isShooting = false
      }, 100)
    },
    btnFunc({ icon }) {
      switch (icon) {
        case 'note':
          this.dictDialog = true
          break
        case 'speaker':
          this.activeIcon = 'speaker'
          break
        case 'video-small':
          this.videoStretch('small')
          break
        case 'video-big':
          this.videoStretch('big')
          break
      }
    },
    async zoomFunc() {
      this.titles = this.videoDialogData.deviceName
      // if (this.videoDialogData.channelId) {
      //   this.channelId = this.videoDialogData.channelId
      //   this.$nextTick(() => {
      //     this.getRealTimeAddress()
      //   })
      // } else {
      let res = await deviceDHDetail({
        ip: this.videoDialogData.deviceNo || this.videoDialogData.id
      })
      if (res.result == 0 && res.data.length !== 0) {
        this.channelId = res.data[0].deviceCode
        this.videoDialog = true
        this.$nextTick(() => {
          this.getRealTimeAddress()
        })
      } else {
        this.$message({ type: 'warning', message: '获取不到设备编码!', duration: 10000 })
      }
      // }
    },
    checkFunc() {
      this.$store.commit('updateCount')
      this.checked = !this.checked
    },
    changeButton(data) {
      this.startTalk()
      this.activeIcon = data.icon
    },
    async getRealTimeAddress() {
      let data = JSON.stringify({
        channelId: this.channelId + '$1$0$0',
        streamType: '1',
        type: 'rtsp'
      })
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://10.7.2.71:9081/scene/admin/real-time-address',
        headers: {
          Authorization: 'bearer 1:a28955dc-5a6a-48b9-9e1c-073504b2a30c',
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios
        .request(config)
        .then((res) => {
          this.DHVideoUrl = res.data.data.url
          setTimeout(() => {
            this.initFun()
          }, 100)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    //视频播放
    initFun() {
      videoPlayer = null
      videoPlayer = new this.$DH({
        isResetConnect: true, // 是否断线重连 默认-true
        videoId: this.radomID, // 唯一id，不能重复
        shieldClass: ['saloon', 'about', 'learn'],
        coverShieldClass: [], // 需要在某个DOM里展示播放器，超出此DOM隐藏插件的类名
        parentIframeShieldClass: [], // 有 iframe 的情况下，处理 top 层 DOM 元素需要出现在播放器上方的类名。
        division: 1, //初始化创建窗口个数
        showBar: false,
        windowType: beforeType === '0' ? 0 : 3,
        createSuccess: (versionInfo) => {
          console.log('播放器创建成功', versionInfo)
          setTimeout(() => {
            this.setRealTime()
          }, 100)
        },
        createError: (err) => {
          console.log('播放器创建失败', err)
        },
        // 插件公共回调方法
        dhPlayerMessage: (info, msg) => {
          switch (msg.code) {
            case 701:
              console.log('对讲操作异常：', msg.message)
              break
            case 702:
              console.log('对讲操作异常：', msg.message)
              break
            case 703:
              console.log('对讲操作异常：', msg.message)
              break
            case 706:
              // 点击主辅码流切换按钮后触发的回调
              // 这里改变码流
              console.log(info, '这是窗口的通道信息')
              console.log(
                msg.streamType,
                '这里会返回切换后的码流 1-表示切换到主码流 2-表示切换到辅码流'
              )
              // videoPlayer.realByUrl({
              //   channelId: $('#realChannel').val(),
              //   snum: currentIndex,
              //   path: url,
              //   redirect, //是否重定向，true-重定向，false-非重定向，默认true
              //   isSubStream: true // 主辅码流切换后，多传入该参数，告知插件是主辅码流切换 默认传true
              // })
              break
          }
        },
        // 实时预览，成功回调
        realSuccess: (info) => {},
        // 实时预览，错误回调
        realError: (info, err) => {},
        // 对讲，成功回调
        talkSuccess: (info) => {},
        // 对讲，错误回调
        talkError: (info, err) => {},
        // 录像，成功回调
        playbackSuccess: (info) => {},
        // 录像，错误回调
        playbackError: (info, err) => {},
        // 录像，播放完成回调 (startPlayback才有的回调)
        playbackFinish: (info) => {},
        // 抓图成功回调
        snapshotSuccess({ base64Url, path }, info) {
          //抓图成功回调
          // picCap(
          //   {
          //     base64Url,
          //     path
          //   },
          //   info
          // )
        },
        // 本地录像下载成功回调
        videoDownloadSuccess(path, info) {
          //本地录像下载成功提示
          alert('本地录像已下载到' + path)
        },
        // 关闭视频窗口回调
        closeWindowSuccess: ({ isAll, snum, channelList }) => {},
        // 单击窗口回调
        clickWindow: (snum, info) => {
          // 获取当前点击的窗口
          currentIndex = snum
          // $('#windowIndex').val(snum)
          console.log(`当前点击了第${snum}个窗口`)
        },
        // 双击窗口回调
        dbClickWindow: (snum) => {
          // 获取当前双击的窗口
          currentIndex = snum
          console.log(`当前双击了第${snum}个窗口`)
        },
        // 切换窗口数量回调
        changeDivision: (division) => {
          divisionNum = division
          // $('#divisionNum').val(division)
        },
        // 对讲回调 (点击播放器上方窗口对讲按钮后触发)
        notifyTalk: ({ channelId, snum }) => {
          // 这里发起对讲请求
          videoPlayer.talkByUrl({
            snum: currentIndex,
            channelId: this.channelId,
            path: this.DHTalkUrl,
            redirect: false,
            audioType: 2,
            audioBit: 16,
            sampleRate: 8000,
            talkType: 1
          })
        },
        // 拖拽录像回调 (仅适用于rtsp流地址传入情况)
        switchStartTime({ startTime, snum }) {
          //录像回放拖拽进度条
          // 拖拽插件进度条后，会返回 startTime 拖拽后的时间节点。
          // snum = snum + 1
          // var url = $('#recordInput' + snum).val()
          // let endTime = getUrlParam(url, 'endtime') - 0
          // if (startTime > endTime) {
          //   alert('开始时间不得大于结束时间')
          //   return false
          // }
          // 此处请重新请求流
        },
        // 播放完当前录像回调 (仅适用于rtsp流地址传入情况)
        replay: (snum, seekTime) => {
          //监听录像回放结束消息
          // 录像播放完成后就会触发该函数，可以做其他业务，如继续播放或停止播放
          alert('第' + (snum + 1) + '个窗口录像播放结束, 结束时间为：' + seekTime)
        },
        // rtsp 流下载录像成功回调
        downloadRecordSuccess: () => {}
      })
      // videoPlayer = new this.$DH({
      //   // videoId: 'DHVideoPlayer',
      //   videoId: this.radomID,
      //   shieldClass: ['saloon', 'about', 'learn'],
      //   num: 1, //初始化创建窗口个数
      //   windowType: beforeType === '0' ? 0 : 3,
      //   showBar: false,
      //   createSuccess: (versionInfo) => {
      //     console.log('播放器创建成功', versionInfo)
      //     setTimeout(() => {
      //       this.setRealTime()
      //     }, 100)
      //   },
      //   createError: (err) => {
      //     console.log('播放器创建失败', err)
      //   },
      //   // 插件公共回调方法
      //   dhPlayerMessage: (info, err) => {},
      //   // 实时预览，成功回调
      //   realSuccess: (info) => {},
      //   // 实时预览，错误回调
      //   realError: (info, err) => {},
      //   // 对讲，成功回调
      //   talkSuccess: (info) => {},
      //   // 对讲，错误回调
      //   talkError: (info, err) => {},
      //   // 录像，成功回调
      //   playbackSuccess: (info) => {},
      //   // 录像，错误回调
      //   playbackError: (info, err) => {},
      //   // 录像，播放完成回调 (startPlayback才有的回调)
      //   playbackFinish: (info) => {},
      //   // 抓图成功回调
      //   snapshotSuccess({ base64Url, path }, info) {
      //     //抓图成功回调
      //     // picCap(
      //     //   {
      //     //     base64Url,
      //     //     path
      //     //   },
      //     //   info
      //     // )
      //   },
      //   // 本地录像下载成功回调
      //   videoDownloadSuccess(path, info) {
      //     //本地录像下载成功提示
      //     alert('本地录像已下载到' + path)
      //   },
      //   // 关闭视频窗口回调
      //   closeWindowSuccess: ({ isAll, snum, channelList }) => {},
      //   // 单击窗口回调
      //   clickWindow: (snum, info) => {
      //     // 获取当前点击的窗口
      //     // currentIndex = snum
      //     // $('#windowIndex').val(snum)
      //     console.log(`当前点击了第${snum}个窗口`)
      //   },
      //   // 双击窗口回调
      //   dbClickWindow: (snum) => {
      //     // 获取当前双击的窗口
      //     // currentIndex = snum
      //     console.log(`当前双击了第${snum}个窗口`)
      //   },
      //   // 切换窗口数量回调
      //   changeDivision: (division) => {
      //     // divisionNum = division
      //     // $('#divisionNum').val(division)
      //   },
      //   // 对讲回调 (仅适用于rtsp实时流传入后点击对讲的情况)
      //   notifyTalk: ({ channelId, snum }) => {},
      //   // 拖拽录像回调 (仅适用于rtsp流地址传入情况)
      //   switchStartTime({ startTime, snum }) {
      //     // //录像回放拖拽进度条
      //     // snum = snum + 1
      //     // var url = $('#recordInput' + snum).val()
      //     // let endTime = getUrlParam(url, 'endtime') - 0
      //     // if (startTime > endTime) {
      //     //   alert('开始时间不得大于结束时间')
      //     //   return false
      //     // }
      //     // setPlayback()
      //   },
      //   // 播放完当前录像回调 (仅适用于rtsp流地址传入情况)
      //   replay: (snum) => {
      //     //监听录像回放结束消息
      //     alert('第' + (snum + 1) + '个窗口录像播放结束')
      //   },
      //   // rtsp 流下载录像成功回调
      //   downloadRecordSuccess: () => {}
      // })
    },
    //播放视频
    setRealTime() {
      let redirect = true
      var url = this.DHVideoUrl
      if (!url) {
        return false
      }
      videoPlayer.realByUrl({
        channelId: this.channelId + '$1$0$0',
        snum: currentIndex,
        path: url,
        redirect //是否重定向，true-重定向，false-非重定向，默认true
      })
    },

    //开启语音
    async startTalk() {
      if (this.activeIcon == 'mic-mute') {
        let data = JSON.stringify({
          source: '',
          deviceCode: this.channelId,
          talkType: '1',
          target: '',
          audioBit: '16',
          audioType: '1',
          broadcastChannels: '',
          sampleRate: '8000',
          talkmode: '',
          channelSeq: '0'
        })
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'http://10.7.2.71:9081/scene/admin/start-talk',
          headers: {
            Authorization: 'bearer 1:a28955dc-5a6a-48b9-9e1c-073504b2a30c',
            'Content-Type': 'application/json'
          },
          data: data
        }
        await axios
          .request(config)
          .then((res) => {
            this.DHTalkUrl = res.data.data.url
            this.startTalkPlay()
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.closeTalk()
      }
    },
    startTalkPlay() {
      var urltalk = this.DHTalkUrl
      videoPlayer.talkByUrl({
        snum: currentIndex,
        channelId: this.channelId,
        path: urltalk,
        redirect: false,
        audioType: 2,
        audioBit: 16,
        sampleRate: 8000,
        talkType: 1
      })
      this.activeIcon = 'mic'
    },
    //关闭选中窗口对讲
    closeTalk() {
      videoPlayer.closeTalk({
        snum: currentIndex,
        isAll: false
      })
      this.activeIcon = 'mic-mute'
    },
    //大华摄像头伸缩
    async videoStretch(flag, command) {
      let data = JSON.stringify({
        channelId: this.channelId + '$1$0$0',
        operateType: '1',
        direct: flag == 'big' ? '1' : '2',
        step: '1',
        command: command ? '0' : '1',
        extend: ''
      })
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://10.7.2.71:9081/scene/admin/operate-camera',
        headers: {
          Authorization: 'bearer 1:a28955dc-5a6a-48b9-9e1c-073504b2a30c',
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios
        .request(config)
        .then((res) => {
          console.log(res)
          if (!command) {
            setTimeout(() => {
              this.videoStretch(flag, true)
            }, 1000)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    //大华摄像头转向
    async videoTurn(flag, command) {
      let data = JSON.stringify({
        channelId: this.channelId + '$1$0$0',
        direct: flag,
        stepX: '1',
        stepY: '1',
        command: command ? '0' : '1',
        extend: ''
      })
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://10.7.2.71:9081/scene/admin/operate-direct',
        headers: {
          Authorization: 'bearer 1:a28955dc-5a6a-48b9-9e1c-073504b2a30c',
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios
        .request(config)
        .then((res) => {
          console.log(res)
          if (!command) {
            setTimeout(() => {
              this.videoTurn(flag, true)
            }, 1000)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.video-card {
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__badge {
    position: absolute;
    z-index: 1;
    background: rgba(9, 45, 65, 0.66);
    border-radius: 0 0 25px 0;
    padding: 3px 20px 3px 3px;
    display: flex;
    gap: 6px;
    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}
</style>

<style lang="less">
@import url('@/style/variables.less');
.el-dialog {
  padding: 10px;
  &.screen-record-diaolog {
    .img-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      margin-top: 24px;
      .img-item {
        display: grid;
        place-items: center;
        gap: 10px;
        img {
          width: 100%;
        }
        span {
          @normalText();
        }
      }
    }
    .time-select {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        @normalText();
      }
    }
    .el-date-editor {
      height: 32px;
      line-height: 32px !important;
      border-radius: unset;
      .el-range-separator {
        line-height: 32px;
      }
    }
  }
  &.tags-dialog {
    .hint {
      margin-bottom: 16px;
    }
    .tags-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .el-tag {
        font-size: 14px;
        @normalText();
        border-color: #51e3ff;
        cursor: pointer;
        &.active,
        &:hover {
          background: #409eff;
          background-image: unset;
          -webkit-text-fill-color: unset;
          color: #fff;
        }
      }
    }
    .common-dialog__footer {
      margin-top: 42px;
      button {
        padding: 8px 18px;
      }
      .el-button--default {
        background-color: unset;
        @normalText();
        border-color: rgba(153, 225, 250, 1);
      }
    }
  }
  &.video-dialog {
    background-color: unset;
    .el-dialog__headerbtn {
      right: 0;
      z-index: 1;
      transform: translate(75%, 25%);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      font-size: 24px;
      background: linear-gradient(to top, #51e3ff, #ade6fb);
      i {
        color: #fff;
        font-weight: bold;
        margin: auto;
      }
    }
    .edge {
      top: 54px;
      &.right-top {
        top: 54px;
      }
    }
    .actionsNew {
      display: flex;
      gap: 8px;
      position: relative;
      z-index: 1;
      background: rgba(9, 45, 65, 0.528);
      padding: 6px 24px 6px 6px;
      border-radius: 0 0 12px 0;
      i {
        font-size: 24px;
        cursor: pointer;
        background: linear-gradient(to top, #ade6fb 0%, #fff 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all 0.5s ease-in-out;
        &:hover {
          text-shadow: 0 0 1px #46bcff;
        }
      }
    }
    .video-dialog__btm_new {
      gap: 65px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      bottom: 0;
      left: 0;
      aspect-ratio: 1320 / 84;
      background-image: url('@/assets/svg/layout/bottom-dialog.svg');
      width: 100%;
      .yun-tai {
        height: 100%;
        position: relative;
        top: -20px;
        .pos {
          position: absolute;
          width: 20px;
          height: 20px;
          cursor: pointer;
          &.top {
            top: 0;
            left: 44%;
          }
          &.bottom {
            top: 55px;
            left: 44%;
          }
          &.right {
            top: 26px;
            left: 60%;
          }
          &.left {
            top: 26px;
            left: 27%;
          }
          &.top-left {
            top: 4px;
            left: 31%;
          }
          &.top-right {
            top: 4px;
            left: 57%;
          }
          &.bottom-left {
            top: 47px;
            left: 31%;
          }
          &.bottom-right {
            top: 47px;
            left: 57%;
          }
        }
      }
      .animate-button {
        width: 100px;
        margin: 0 20px;
        transform: translate(-5%, -10%) scale(0.7);
        svg {
          width: 52px;
          height: 52px;
        }
      }
      .item {
        display: grid;
        place-items: center;
        margin-bottom: 2.5%;
        cursor: pointer;
        opacity: 0.75;
        &:hover {
          opacity: 1;
        }
        &.left {
          svg {
            width: 21px;
            height: 21px;
            padding: 8px;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          padding: 10px;
          border-radius: 50%;
          background: rgba(0, 31, 36, 0.45);
        }
        label {
          font-size: 12px;
          background-image: linear-gradient(to top, rgba(253, 253, 253, 1), rgba(153, 225, 250, 1));
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .el-dialog__header {
      text-align: center;
      background-image: url('~@/assets/svg/dialog-header.svg');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
    }
    .el-dialog__body {
      box-shadow: 0 0 0 1px #51e3ff;
      padding: 0;
    }
    .el-dialog__title {
      background-image: linear-gradient(to top, rgba(253, 253, 253, 1), rgba(153, 225, 250, 1));
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    video {
      width: 100%;
    }
    .filter {
      filter: brightness(0.5);
    }
  }
}
</style>
