import router from './router'

router.beforeEach(async (to, from, next) => {
  const hasType = sessionStorage.getItem('saas-type')
  if (hasType && hasType == 'sw') {
    //只有水务权限
    if (to.path == '/' || to.path == '/login') {
      next()
    } else {
      next({ path: '/water' })
    }
  } else {
    next()
  }
})
