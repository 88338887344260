export const COMM_LIST = [
  { comm_code: 'T0001', comm_name: '镇级数据', position: [121.381929, 31.023711] },
  { comm_code: 'M0002', comm_name: '飞碟苑', position: [121.394059, 31.029245] },
  { comm_code: 'M0007', comm_name: '夏朵园', position: [121.396316, 31.025256] },
  { comm_code: 'M0003', comm_name: '中西街', position: [121.369547, 31.02876] },
  { comm_code: 'M0009', comm_name: '华银坊', position: [121.391828, 31.024148] },
  { comm_code: 'M0008', comm_name: '敬南苑', position: [121.368887, 31.032367] },
  { comm_code: 'M0011', comm_name: '银康苑', position: [121.388053, 31.021765] },
  { comm_code: 'M0010', comm_name: '银春苑', position: [121.388235, 31.024068] },
  { comm_code: 'M0012', comm_name: '景城馨苑', position: [121.370545, 31.017311] },
  { comm_code: 'M0013', comm_name: '保利佳苑', position: [121.379168, 31.019813] },
  { comm_code: 'M0015', comm_name: '景城和苑', position: [121.3743, 31.020555] },
  { comm_code: 'M0014', comm_name: '保利雅苑', position: [121.368828, 31.02171] },
  { comm_code: 'M0016', comm_name: '景城品雅苑', position: [121.381171, 31.0256] },
  { comm_code: 'M0017', comm_name: '乐康苑', position: [121.435873, 30.963535] }
]
