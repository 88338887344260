<template>
  <div class="curve-wrap" :class="{ reverse: reverse }">
    <div
      v-for="idx in num"
      :key="`curve${idx}`"
      class="item full-bg"
      :style="{ left: `${idx * 20}px`, animationDelay: `${idx * ratio}s` }" />
  </div>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    num: {
      type: Number,
      default: 8
    }
  },
  computed: {
    ratio() {
      return this.reverse ? 0.1 : -0.1
    }
  }
}
// rgba(49, 165, 207, 0.3) 30%,
// rgba(216, 216, 216, 0.25) 50%,
// rgba(0, 0, 0, 0) 80%
</script>

<style lang="less">
.curve-wrap {
  position: relative;
  display: flex;
  gap: 12px;
  &.reverse {
    .item {
      transform: skew(-45deg);
    }
  }
  .item {
    height: 28px;
    width: 16px;
    animation: flash 2s linear infinite;
    background: linear-gradient(
      to top,
      rgba(59, 200, 252, 0.7) 30%,
      rgba(216, 216, 216, 0.25) 50%,
      rgba(0, 0, 0, 0) 80%
    );
    transform: skew(45deg);
  }
  @keyframes flash {
    0% {
      background-size: 100% 100%;
    }
    25% {
      background-size: 100% 200%;
    }
    50% {
      background-size: 100% 500%;
    }
    75% {
      background-size: 100% 200%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
}
</style>
