<template>
  <div class="map-wrap">
    <el-amap :id="radomID" ref="amap" :zoom="14" :amap-manager="amapManager">
      <el-amap-marker
        v-for="marker in markers"
        :key="marker.id"
        :position="marker.position"
        :icon="require('@/assets/svg/marker.svg')"
        :events="markerEvents"
        :ext-data="marker" />
      <!-- 通用点位 -->
      <el-amap-marker
        v-for="marker in commonMarkers"
        :key="marker.id"
        :position="marker.position"
        :icon="marker.icon"
        :events="markerEventsCommon"
        :ext-data="marker"
        :label="marker.label" />
      <!-- 视频点位 -->
      <el-amap-marker
        v-for="marker in videoMarkers"
        :key="marker.id"
        :position="marker.position"
        :icon="marker.icon"
        :events="marker.events"
        :ext-data="marker"
        :label="marker.label" />
      <!-- 视频选中点位 -->
      <el-amap-marker
        v-for="(marker, idx) in $store.state.deviceMarkers"
        :key="`devicemk${idx}`"
        :position="marker"
        :icon="
          require(get(SelectVideoMarkers, '[0].position[0]') === get(marker, '[0]')
            ? '@/assets/svg/videoMarkerS.svg'
            : '@/assets/svg/marker.svg')
        "
        :events="markerEvents"
        :ext-data="marker"
        ref="markRef" />
      <!-- <el-amap-info-window
        v-if="windowVis"
        :visible="windowVis"
        :position="windowPos"
        :content="windowContent"
        :events="windowEvents"
      /> -->
      <el-amap-polyline
        :path="patrolArr"
        stroke-color="#DC143C"
        :stroke-opacity="0.5"
        :stroke-weight="5" />
      <el-amap-polygon
        v-for="(item, idx) in POLYGONS"
        :key="idx"
        :path="item.path"
        :vid="idx"
        :fill-color="item.color"
        fill-opacity="0.25"
        stroke-weight="2"
        stroke-color="#4169e1"
        stroke-opacity="1" />
      <el-dialog
        custom-class="basic-detail common-dialog"
        :visible.sync="videoListDialogVisable"
        width="400px"
        append-to-body>
        <div class="edge" />
        <div class="edge right-top" />
        <div class="edge left-bottom" />
        <div class="edge right-bottom" />
        <div slot="title" class="common-dialog__header">
          <LadderAnimate />
          <h3>设备列表</h3>
          <LadderAnimate side="right" />
        </div>
        <div class="video-list-box">
          <div class="video-list-box-item">设备1</div>
          <div class="video-list-box-item">设备1</div>
          <div class="video-list-box-item">设备1</div>
          <div class="video-list-box-item">设备1</div>
        </div>
      </el-dialog>
      <VideoItemDialog
        :videoDialogFlag="videoDialogFlag"
        :videoDialogData="videoData"
        @closeDialog="closeDialog" />
    </el-amap>
  </div>
</template>

<script>
import AMap from 'vue-amap'
import { POLYGONS } from './data'
import { AMapManager } from 'vue-amap'
import { get, isEmpty, dropRight, size, forEach } from 'lodash'
import { mapGetters } from 'vuex'
import VideoItemDialog from '@/components/VideoItemDialog.vue'
import ELECARD_ICON from '@/assets/svg/marker.png'
import ELECARD_ICON_OUTLINE from '@/assets/svg/marker-grey.svg'
import CAR from '@/assets/svg/car.svg'
import VIDEO from '@/assets/svg/videoMarker.svg'
const amapManager = new AMapManager()
const MAX_NODE_NUM = 8
const INITIAL_POINT = [
  [121.36813, 31.118899],
  [121.364621, 31.105174],
  [121.385767, 31.113473]
]

export default {
  components: {
    VideoItemDialog
  },
  props: {
    ScopeMaps: {
      type: Array,
      default: () => []
    },
    pathMarkers: {
      type: Array,
      default: () => [1]
    },
    path: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      radomID: '',
      boost: false,
      point: null,
      windowVis: false,
      windowPos: [],
      windowContent: undefined,
      windowEvents: {
        close: () => {
          this.windowVis = false
        }
      },
      markerEvents: {
        click: (e) => {
          if (this.videoListShow) {
            //说明一个点位包含多个设备，需要弹列表
            this.videoListDialogVisable = true
          } else {
            const _data = e.target.getExtData()
            this.windowContent = `<p>${_data.company}</p><p>${_data.imei || '无'}<p>`
            this.windowVis = true
            this.windowPos = [e.lnglat.lng, e.lnglat.lat]
          }
        }
      },
      markerEventsCommon: {
        click: (e) => {
          // if (this.videoListShow) {
          //   //说明一个点位包含多个设备，需要弹列表
          //   this.videoListDialogVisable = true
          // } else {
          //   const _data = e.target.getExtData()
          //   this.windowContent = `<p>${_data.company}</p><p>${_data.imei || '无'}<p>`
          //   this.windowVis = true
          //   this.windowPos = [e.lnglat.lng, e.lnglat.lat]
          // }
        }
      },
      // markerEventsVideo: {
      //   click: (e) => {
      //     const _data = e.target.getExtData()
      //     this.videoDialogFlag = true
      //     this.videoData = _data
      //   }
      // },
      polygons: [],
      amapManager,
      POLYGONS,
      map: null,
      mouseTool: null,
      polyEditor: null,
      tools: null,
      counter: 0,
      polygonsCenter: [121.36813, 31.118899],
      patrolArr: [],
      markers: [],
      videoMarkers: [], //视频设备点位
      SelectVideoMarkers: [], //选中设备高亮
      videoListShow: false, //是否点击地图点位后，先显示该区域设备列表
      videoListDialogVisable: false,
      commonMarkers: [], //通用点位
      videoDialogFlag: false,
      videoData: {},
      myVideoD: null
    }
  },
  computed: {
    ...mapGetters(['mapPointData', 'mapMarkers'])
  },
  watch: {
    mapMarkers() {
      if (this.map) {
        this.map.setFitView(this.map.getAllOverlays('marker'))
        this.map.setZoom(14)
      }
    },
    mapPointData(val) {
      const data = JSON.parse(val)
      const { name } = data
      this.commonMarkers = []
      this.videoMarkers = []
      this.SelectVideoMarkers = []
      if (name == 'patrol') {
        let patrolArr = []
        let markers = []
        data.detailData.forEach((ele, idx) => {
          patrolArr.push([ele.longitude, ele.latitude])
          markers.push({ position: [ele.longitude, ele.latitude], time: ele.ts, id: idx })
        })
        this.patrolArr = patrolArr
        this.markers = markers
      } else if (name == 'videoPoint') {
        if (data.flag && data.flag == 'multiple') {
          this.videoListShow = true
        } else {
          this.videoListShow = false
        }
        //视频设备撒点
        let videoMarkers = []
        let _that = this
        data.detailData.forEach((ele, idx) => {
          videoMarkers.push({
            position: [ele.longitude, ele.latitude],
            id: `video${idx}`,
            name: ele.name,
            channelId: ele.channelId,
            icon:
              data.type && data.type == '车辆数量'
                ? CAR
                : data.type && data.type == '考勤数量'
                ? ELECARD_ICON
                : VIDEO,
            label: {
              offset: data.type && data.type == '车辆数量' ? [-5, 60] : [-5, 40], //设置文本标注偏移量
              content: `<div id="windowContent" style="color: #fff;font-size:12px;background:transparent;cursor: pointer;">${ele.name}</div>` //设置文本标注内容
            },
            events: {
              click() {
                _that.videoDialogFlag = true
                _that.videoData = ele
              }
            }
          })
        })
        this.videoMarkers = videoMarkers
      } else if (name == 'videoSelectPoint') {
        //视频设备撒点
        let { longitude, latitude, name, channelId } = data.detailData
        let SelectVideoMarkers = [
          {
            position: [longitude, latitude],
            id: `videoS2`,
            name: name,
            channelId: channelId,
            label: {
              offset: [-5, 40], //设置文本标注偏移量
              content: `<div id="windowContent" style="color: #fff;font-size:12px;background:transparent;cursor: pointer;">${name}</div>` //设置文本标注内容
            },
            events: {
              click() {
                this.videoDialogFlag = true
                this.videoData = data.detailData
              }
            }
          }
        ]
        console.log(SelectVideoMarkers, 'SelectVideoMarkers')
        this.SelectVideoMarkers = SelectVideoMarkers
        this.map.setCenter([longitude, latitude])
        this.map.setZoom(17)
      } else if (name == 'commonPoint') {
        console.log(data.detailData, 'data.detailData')
        //通用撒点
        let commonMarkers = []
        data.detailData.forEach((ele, idx) => {
          commonMarkers.push({
            position: [ele.longitude, ele.latitude],
            id: `common${idx}`,
            name: ele.name,
            icon: ele.isOnline == 1 ? ELECARD_ICON : ELECARD_ICON_OUTLINE,
            label: {
              offset: [-5, 50], //设置文本标注偏移量
              content: `<div id="windowContent" style="color: #fff;font-size:12px;background:transparent;cursor: pointer;">${ele.name}</div>` //设置文本标注内容
            }
          })
        })
        console.log(commonMarkers, 'commonMarkers')
        this.commonMarkers = commonMarkers
      } else if (!name) {
        this.$nextTick(() => {
          if (!isEmpty(this.SelectVideoMarkers) && this.map) {
            this.map.setFitView(this.map.getAllOverlays('marker'))
            this.map.setZoom(17)
          }
        })
      } else {
        this.SelectVideoMarkers = []
      }
    },
    $route(to, from) {
      this.commonMarkers = []
      this.videoMarkers = []
      this.SelectVideoMarkers = []
      // this.clearTrack()
    }
  },
  created() {
    this.radomID = `bar${Math.random() * 10 + 4}`
  },
  mounted() {
    const getMap = setInterval(() => {
      if (amapManager.getMap()) {
        this.map = amapManager.getMap()
        clearInterval(getMap)
        if (isEmpty(this.path)) {
          this.map.setCenter([121.375323, 31.114132])
          this.map.setMapStyle('amap://styles/darkblue')
        }
      }
    }, 500)
  },
  methods: {
    get,
    resetMap() {
      this.SelectVideoMarkers = []
      this.commonMarkers = []
      this.$store.dispatch('mapAction/mapPointData', '{}')
      this.$store.commit('updateMarkers', [])
    },
    draw(editVal) {
      if (!this.polyEditor) {
        // this.initDraw(editVal)
      }
      this.polyEditor.open()
    },
    finishDraw() {
      this.closePolyEditor()
      this.$emit('done', this.polygons)
    },
    moveAction() {
      this.point.moveAlong(
        this.path,
        this.boost ? 2000 : 4000,
        function (k) {
          return k
        },
        true
      )
    },
    clearTrack() {
      this.map.clearMap()
      this.initArea()
    },
    // initTrack() {
    //   if (this.point) {
    //     this.clearTrack()
    //   }
    //   const _map = amapManager.getMap()
    //   this.point = new AMap.Marker({
    //     icon: require('@/assets/image/marker.svg'),
    //     map: _map,
    //     offset: new AMap.Pixel(-25, -60)
    //   })
    //   const _poly = new AMap.Polyline({
    //     map: _map,
    //     path: this.path,
    //     showDir: true,
    //     strokeColor: '#28F',
    //     strokeWeight: 6,
    //     strokeOpacity: 1,
    //     strokeStyle: 'solid'
    //   })
    //   this.maxLength = _poly.getLength()
    //   let passedPolyline = new AMap.Polyline({
    //     map: _map,
    //     strokeColor: '#AF5',
    //     strokeWeight: 6
    //   })
    //   this.point.on('moving', (e) => {
    //     passedPolyline.setPath(e.passedPath)
    //     this.progress = passedPolyline.getLength()
    //   })
    //   _map.setFitView([_poly])
    //   this.moveAction()
    // },
    initArea() {
      forEach(POLYGONS, (item) => {
        const _polygon = new AMap.Polygon({
          path: item.path,
          fillColor: item.color,
          fillOpacity: 0.25,
          strokeColor: '#FF33FF',
          strokeWeight: 2,
          strokeOpacity: 1,
          zIndex: 50
        })
        this.map.add(_polygon)
      })
    },
    initDraw(editVal) {
      this.polygons = Object.assign([], editVal ? editVal : INITIAL_POINT)
      const polygon = new AMap.Polygon({
        path: this.polygons,
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        zIndex: 50,
        raiseOnDrag: true,
        draggable: true
      })
      this.map.add(polygon)
      this.polyEditor = new AMap.PolyEditor(this.map, polygon)
      polygon.on('click', () => {
        this.finishDraw()
      })
      polygon.on('dragend', () => {
        this.polygons = polygon.getPath()
      })
      this.polyEditor.on('adjust', () => {
        console.log('adjjjj')
      })
      this.polyEditor.on('dragging', () => {
        console.log('adjjjj')
      })
      this.polyEditor.on('addnode', () => {
        if (size(this.polygons) > MAX_NODE_NUM) {
          this.polygons = dropRight(this.polygons)
          polygon.setPath(this.polygons)
          setTimeout(() => {
            this.polyEditor.close()
            this.map.clearMap()
            this.initArea()
            this.map.add(polygon)
            this.polyEditor.open()
          })
        }
      })
      this.polyEditor.on('end', () => {
        this.map.remove([polygon])
      })
    },
    closePolyEditor() {
      if (this.polyEditor) {
        this.polyEditor.close()
        this.polyEditor = null
      }
    },
    closeDialog() {
      this.videoDialogFlag = false
      this.videoData = {}
    }
  }
}
</script>
<style lang="less" scoped>
.map-wrap {
  height: calc(100% + 60px);
  width: calc(100% - 4px);
  position: relative;
  top: -60px;
  left: 2px;
  /deep/ .amap-icon img {
    width: 40px !important;
  }
  .close-edit {
    position: absolute;
    z-index: 1;
    left: 8px;
    top: 8px;
    padding: 10px 20px;
  }
}
.map-box {
  position: relative;
  .select-box {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
/deep/ .amap-marker-label {
  border: unset;
  background: transparent;
}
.video-list-box-item {
  background: linear-gradient(to bottom, #46bbff69, rgba(70, 188, 255, 0), #46bbff69);
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
