const state = {
  mapPointData: '{}'
}
const mutations = {
  MAP_POINT_DATA: (state, mapPointData) => {
    state.mapPointData = mapPointData
  }
}
const actions = {
  mapPointData(context, mapPointData) {
    context.commit('MAP_POINT_DATA', mapPointData)
  }
}
export default { namespaced: true, state, mutations, actions }
