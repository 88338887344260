import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import dialogAction from '@/store/modules/dialogAction'
import mapAction from '@/store/modules/mapAction'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkVisiable: false,
    checkedCount: 0,
    currentCommCode: 'T0001',
    deviceMarkers: [],
    alarmTypes: []
  },
  getters,
  mutations: {
    updateAlarmTypes(state, types) {
      state.alarmTypes = types
    },
    updateMarkers(state, arr) {
      state.deviceMarkers = arr
    },
    updateCount(state) {
      state.checkedCount++
    },
    changeCheckVis(state, bool) {
      state.checkVisiable = bool
    },
    updateCommName(state, name) {
      state.currentCommCode = name
    }
  },
  actions: {},
  modules: {
    dialogAction,
    mapAction
  }
})
