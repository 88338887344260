<template>
  <div class="layout">
    <iframe
      style="background: #fff; width: 100%; height: 100%"
      v-if="xzappShow"
      id="xzapp"
      :src="iframeSrc"
      frameborder="0" />
    <div v-show="xzappShow" class="close-iframe cursor" @click="xzappShow = !xzappShow">
      返回主页面
    </div>
    <div class="layout-header">
      <div class="header-side left">
        <span class="date-s mr-50">多云</span><span>空气质量 优</span
        ><span class="time-s ml-50">PM2.5 28</span>
      </div>
      <div class="title-wrap full-bg">
        <Curve />
        <h2 class="title">莘庄镇城市运行"一网统管"平台</h2>
        <Curve :reverse="true" />
      </div>
      <div class="header-side right">
        <span class="date-s mr-50">{{ time.date }}</span
        ><span>{{ time.week }}</span
        ><span class="time-s ml-50">{{ time.time }}</span>
      </div>
      <div class="login-out" @click="loginOut">退出</div>
    </div>
    <div class="slider left" :class="{ slideIn: slideIn, hidden: $route.name === 'DataCenter' }">
      <router-view name="left" />
    </div>
    <div class="slider right" :class="{ slideIn: slideIn, hidden: $route.name === 'DataCenter' }">
      <div class="side-menu m1" v-show="$route.path == '/block'" @click="changeRouter(1)">
        <div>智慧场景</div>
      </div>
      <div
        class="side-menu m2"
        v-show="$route.path == '/block' && showFlag"
        @click="changeRouter(2)">
        <div>智慧监管</div>
      </div>
      <div
        class="side-menu m3"
        v-show="$route.path == '/block' && showFlag"
        @click="changeRouter(3)">
        <div>智慧考勤</div>
      </div>
      <router-view name="right" @videoList="videoList" />
    </div>
    <!-- <ScopeMaps :videoArr="videoArr" ref="mapRef" /> -->
    <JxMap />
    <div class="layout-bottom full-bg" v-if="saasType !== 'sw'">
      <div class="layout-bottom__wrap">
        <div
          v-for="item in PATH_LEFT"
          :key="`lybtn${item.name}`"
          class="btn-item full-bg"
          :class="{ active: item.name === $route.name }"
          @click="changePage(item.name)"
          @mouseenter="enterPage(item.label)">
          <svg-icon :icon-class="item.class" />
          <label>{{ item.label }}</label>
        </div>
      </div>
      <div
        class="animate-button"
        :class="{ active: $route.name === 'DataCenter' }"
        @click="changePage('DataCenter')">
        {{ '城运\n体征' }}
      </div>
      <div class="layout-bottom__wrap">
        <div
          v-for="item in PATH_RIGHT"
          :key="`lybtn${item.name}`"
          class="btn-item full-bg right"
          :class="{ active: item.name === $route.name }"
          @click="changePage(item.name)"
          @mouseenter="enterPage(item.label)">
          <svg-icon :icon-class="item.class" />
          <label>{{ item.label }}</label>
        </div>
      </div>
      <div class="third-menu" v-show="thirdMenuShow" @mouseleave="thirdMenuShow = false">
        <div
          class="third-menu-item"
          v-for="(item, index) in THIRD_MENU"
          :key="`thirdMenu${index}`"
          @click="changePage(item.name)"></div>
      </div>
      <div class="third-menu right" v-show="thirdMenuShow2" @mouseleave="thirdMenuShow2 = false">
        <div
          class="third-menu-item"
          v-for="(item, index) in THIRD_MENU2"
          :key="`thirdMenu${index}`"
          @click="changePage(item.name)"></div>
      </div>
    </div>
    <!-- <transition name="water">
      <waterVideo class="right waterVideo" v-if="waterVideoIf"></waterVideo>
    </transition> -->
  </div>
</template>

<script>
import { COMM_LIST } from '@/assets/StaticData'
import Curve from '@/components/AnimationCollection/CurveFlash.vue'
import Jieqi from '@/utils/jieqi'
// import waterVideo from '@/views/Water/waterVideo.vue'
import JxMap from '@/components/JxMap'
import ScopeMaps from '@/components/Map/ScopeMaps'
const PATH_LEFT = [
  {
    label: '智慧街区',
    class: 'house-tree',
    name: 'BlockView'
  },
  {
    label: '智慧社区',
    class: 'house',
    name: 'CommunityView'
  }
  // {
  //   label: '智慧园区',
  //   class: 'community',
  //   name: 'CampusView'
  // }
]
const PATH_RIGHT = [
  {
    label: '智慧水务',
    class: 'water',
    name: 'WaterView'
  },
  {
    label: '智慧园区',
    class: 'community',
    name: 'CampusView'
  }
  // {
  //   label: '智慧监管',
  //   class: 'camera',
  //   name: 'MonitorView'
  // },
  // {
  //   label: '智慧考勤',
  //   class: 'monitor',
  //   name: 'AttendanceView'
  // }
]
const THIRD_MENU = [
  {
    label: '智慧场景',
    class: '',
    name: 'BlockView2'
  },
  {
    label: '智慧监管',
    class: 'camera',
    name: 'MonitorView'
  },
  {
    label: '智慧考勤',
    class: 'monitor',
    name: 'AttendanceView'
  }
]
const THIRD_MENU2 = [
  {
    label: '综合平台',
    class: '',
    name: 'zonghe'
  }
  // {
  //   label: '管理平台',
  //   class: 'camera',
  //   name: 'guanli'
  // }
]
export default {
  components: {
    JxMap,
    Curve,
    ScopeMaps
    // waterVideo
  },
  data() {
    return {
      iframeSrc: '',
      commCode: this.$store.state.currentCommCode,
      COMM_LIST,
      slideIn: false,
      PATH_LEFT,
      PATH_RIGHT,
      THIRD_MENU,
      THIRD_MENU2,
      leftExpend: false,
      rightExpended: false,
      time: {
        date: '',
        time: '00:00:00',
        week: '',
        calen: '',
        jieqi: ''
      },
      timer: null,
      xzappShow: false,
      videoArr: [],
      showFlag: false,
      thirdMenuShow: false,
      thirdMenuShow2: false,
      waterVideoIf: false,
      saasType: sessionStorage.getItem('saas-type')
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.page) {
          this.showFlag = false
        } else {
          this.showFlag = true
        }
      },
      immediate: true,
      deep: true
    },
    $route: {
      handler(val) {
        console.log(val)
        if (val.name == 'WaterView') {
          this.waterVideoIf = true
        } else {
          this.waterVideoIf = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.slideIn = true
      setTimeout(() => {
        this.leftExpend = true
        this.rightExpended = true
      }, 500)
    }, 250)
    this.timer = setInterval(() => {
      this.time = Jieqi.showTime()
    }, 1000)
  },
  methods: {
    loginOut() {
      sessionStorage.removeItem('saas-token')
      sessionStorage.removeItem('saas-type')
      this.$router.push('/')
    },
    videoList(arr) {
      this.videoArr = arr
    },
    changePage(name) {
      if (name === 'CampusView') {
        // window.open('http://xzapp.shmh.gov.cn:8001/RefineZw/totalityMain')
        // window.location.href = 'http://xzapp.shmh.gov.cn:8001/Refine/Totality/Main'
        window.location.href =
          'http://xzapp.shmh.gov.cn:8001/RefineZw/totalityMain?path=' + window.location.href
        // this.xzappShow = true
        // this.$nextTick(() => {
        //   this.iframeSrc =
        //     'http://xzapp.shmh.gov.cn:8001/RefineZw/totalityMain?path=' + this.$route.path
        // })
        return
      }
      if (name === 'zonghe') {
        window.open('http://32.5.9.14:8080/water/login')
        // window.location.href = 'http://32.5.9.14:8080/water/login'
        // this.xzappShow = true
        // this.$nextTick(() => {
        //   this.iframeSrc = 'http://32.5.9.14:8080/water/login'
        // })
        return
      }
      if (name === 'guanli') {
        window.open('http://32.5.9.14:8080/water/main/index')
        // window.location.href = 'http://32.5.9.14:8080/water/main/index'
        // return
        // this.xzappShow = true
        // this.$nextTick(() => {
        //   this.iframeSrc = 'http://32.5.9.14:8080/water/main/index'
        // })
        return
      }
      if (name === 'CommunityView') {
        window.location.href =
          'http://pre-xinzhuang-hairtail-second.saas.famesmart.com/login?autoLogin=1&path=' +
          this.$route.path
        // this.xzappShow = true
        // this.iframeSrc = 'http://pre-xinzhuang-hairtail-second.saas.famesmart.com/login?autoLogin=1'
        return
      }
      // if (name === 'DataCenter') {
      //   this.xzappShow = true
      //   this.iframeSrc = 'http://32.1.8.127/mhcy-jz/#/'
      //   return
      // }
      if (name === 'BlockView2') {
        this.$router.push('/block?page=1')
        return
      }
      this.slideIn = false
      this.leftExpend = false
      this.rightExpended = false
      // this.$refs.mapRef.resetMap()
      setTimeout(() => {
        this.$router.push({ name })
        this.slideIn = true
        setTimeout(() => {
          this.leftExpend = true
          this.rightExpended = true
        }, 500)
      }, 500)
    },
    updateComm(name) {
      this.$store.commit('updateCommName', name)
    },
    changeRouter(flag) {
      if (flag == 1) {
        this.$router.push('/block?page=1')
      } else if (flag == 2) {
        this.$router.push('/monitor')
      } else if (flag == 3) {
        this.$router.push('/attendance')
      }
    },
    enterPage(flag) {
      if (flag == '智慧街区') {
        this.thirdMenuShow = true
      } else if (flag == '智慧水务') {
        this.thirdMenuShow2 = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');
@headerHeight: 163px;
@pad: 24px;
@goldenLine: linear-gradient(
  to right,
  rgba(72, 115, 122, 0) 0%,
  rgba(72, 115, 122, 0) 10%,
  rgba(211, 200, 158, 0.21) 20%,
  rgba(224, 200, 111, 0.45) 30%,
  rgba(243, 200, 44, 0.78) 40%,
  rgba(255, 200, 0, 1) 50%,
  rgba(243, 200, 44, 0.78) 60%,
  rgba(224, 200, 111, 0.45) 70%,
  rgba(211, 200, 158, 0.21) 80%,
  rgba(72, 115, 122, 0) 90%,
  rgba(72, 115, 122, 0) 100%
);
#xzapp {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}
.close-iframe {
  width: 150px;
  height: 40px;
  line-height: 40px;
  position: fixed;
  top: 28px;
  left: 29px;
  z-index: 100001;
  background: linear-gradient(to top, rgba(67, 186, 252, 0.68), rgba(37, 104, 163, 0.29));
  border-radius: 10px;
  letter-spacing: 1px;
  text-shadow: 0 0 4px #10faff;
  &:hover {
    background: linear-gradient(to top, rgba(67, 186, 252, 0.98), rgba(37, 104, 163, 0.29));
  }
}
.layout {
  display: grid;
  grid-template-columns: minmax(500px, 27.5%) 1fr minmax(500px, 27.5%);
  background: #070707;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-image: url('~@/assets/image/background.png');
  .slider {
    position: relative;
    display: flex;
    left: -100%;
    transition: all 0.3s linear;
    background: rgba(0, 50, 83, 0.24);
    box-shadow: 0 0 0 1px #51e3ff;
    height: calc(100vh - @headerHeight + 43px);
    margin-left: 20px;
    padding: 0 0 10px;
    box-sizing: border-box;
    margin-top: -60px;
    z-index: 1;
    &.hidden {
      background-color: unset;
      box-shadow: unset;
    }
    &.left {
      &.slideIn {
        left: 0;
      }
    }
    &.right {
      grid-area: 2 / 3 / span 1 / span 1;
      left: unset;
      right: -100%;
      margin-left: unset;
      margin-right: 20px;
      position: relative;
      &.slideIn {
        right: 0;
      }
      .side-menu {
        width: 46px;
        height: 161px;
        position: absolute;
        left: -46px;
        display: grid;
        place-content: center;
        cursor: pointer;
        div {
          width: 20px;
          height: 100%;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          background: linear-gradient(180deg, #fdfdff 0%, #99e1fa 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &.m1 {
          top: 0;
          background: url('../assets/image/sideMenuActive.png') no-repeat;
          background-size: 46px 161px;
        }
        &.m2 {
          top: 320px;
          background: url('../assets/image/sideMenu.png') no-repeat;
          background-size: 46px 161px;
        }
        &.m3 {
          top: 620px;
          background: url('../assets/image/sideMenu.png') no-repeat;
          background-size: 46px 161px;
        }
      }
    }
  }
  .slider:has(.demo) {
    padding: 0 !important;
  }
  &-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    box-sizing: border-box;
    aspect-ratio: 1720 / 164;
    background-image: url('@/assets/svg/layout/bottom.svg');
    position: absolute;
    width: 45%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 0 1%;
    &__wrap {
      width: 35%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 82px;
      transform: translateY(-30%) scale(0.8);
    }
    .third-menu {
      width: 265px;
      height: 162px;
      background: url('../assets/image/thirdMenu.png') no-repeat;
      position: absolute;
      left: 150px;
      top: -45px;
      z-index: -1;
      &-item {
        width: 100%;
        height: 40px;
        cursor: pointer;
      }
      &.right {
        background: url('../assets/image/thirdMenu3.png') no-repeat;
        left: 1090px;
        top: 27px;
      }
    }
    .btn-item {
      background: linear-gradient(to top, rgba(67, 186, 252, 0.68), rgba(37, 104, 163, 0.29));
      aspect-ratio: 210 / 55;
      position: relative;
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      font-weight: bold;
      transform: skewX(-30deg);
      border-radius: 0 8px 0 8px;
      border-left: 1px solid #78ddfd;
      border-bottom: 1px solid #78ddfd;
      font-size: 24px;
      &.right {
        transform: skewX(30deg);
        border-right: 1px solid #78ddfd;
        border-left: unset;
        border-radius: 8px 0 8px 0;
        &::before {
          background: linear-gradient(to right, rgba(120, 221, 253, 0), rgba(67, 184, 251, 1));
        }
        svg,
        label {
          transform: skewX(-30deg);
        }
        &.active,
        &:hover {
          border-left: unset;
          border-right: 1px solid #f5b649;
          border-bottom: 1px solid #f5b649;
          &::before {
            background: linear-gradient(to right, rgba(240, 184, 80, 0.03), rgba(245, 182, 73, 1));
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, rgba(120, 221, 253, 0), rgba(67, 184, 251, 1));
      }
      svg {
        width: 32px;
        height: 32px;
      }
      svg,
      label {
        cursor: pointer;
        transform: skewX(30deg);
      }
      &.active,
      &:hover {
        border-left: 1px solid #f5b649;
        border-bottom: 1px solid #f5b649;
        background: linear-gradient(to bottom, rgba(254, 190, 77, 0.09), rgba(253, 188, 75, 1));
        &::before {
          background: linear-gradient(to left, rgba(240, 184, 80, 0.03), rgba(245, 182, 75, 1));
        }
      }
    }
  }
  &-header {
    grid-area: auto / span 5;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 450px;
    height: @headerHeight;
    position: relative;
    background-image: url('@/assets/svg/layout/header-bg.svg');
    aspect-ratio: 3840 / 163;
    width: 100%;
    z-index: 1;
    @line: {
      height: 4px;
      width: 10%;
      position: absolute;
      bottom: 0;
      background: @goldenLine;
      background-position: 0 0;
    };
    @keyframes golden {
      0% {
        background-size: 75%;
      }
      25% {
        background-size: 100%;
      }
      50% {
        background-size: 125%;
      }
      75% {
        background-size: 100%;
      }
      100% {
        background-size: 75%;
      }
    }
    @keyframes goldenOther {
      0% {
        background-size: 125%;
      }
      25% {
        background-size: 100%;
      }
      50% {
        background-size: 75%;
      }
      75% {
        background-size: 100%;
      }
      100% {
        background-size: 125%;
      }
    }
    &::before {
      content: '';
      @line();
      animation: golden 2s linear infinite;
      transform: rotate(5deg) translate(-160%, 350%);
    }
    &::after {
      content: '';
      @line();
      animation: goldenOther 2s linear infinite;
      transform: rotate(-5deg) translate(160%, 350%);
    }
    .title-wrap {
      position: relative;
      padding: 0 @pad;
      display: flex;
      place-items: center;
      height: 100%;
      .curve-wrap {
        left: 0;
        top: 10%;
        position: absolute;
        height: 72px;
        transform: translateX(-275%);
        &.reverse {
          left: unset;
          right: 0;
          transform: translateX(275%);
        }
      }
    }
    .title {
      font-size: 48px;
      background-position-y: 0;
      user-select: none;
      position: relative;
      background: url('~@/assets/image/titleBg.jpeg');
      -webkit-background-clip: text;
      background-size: 50%;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 2px;
      animation: titleTurn 5s linear infinite;
      &::before {
        content: '';
        position: absolute;
        background-image: url('@/assets/image/logo.png');
        aspect-ratio: 150 / 132;
        z-index: 1;
        width: 5.5%;
        background-size: 100%;
        bottom: 0;
        left: 50%;
        transform: translate(-46%, 75%);
      }
    }
    .header-side {
      width: 500px;
      margin-top: -40px;
      position: relative;
      &.right,
      &.left {
        font-size: 22px;
        font-weight: 400;
        color: #cae2fb;
        line-height: 25px;
        letter-spacing: 1px;
      }
      &.left::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -27px;
        left: 12%;
        width: 76%;
        height: 80px;
        transform: perspective(1.5em) rotateX(-12deg);
        animation: flash 3s linear infinite;
        background: linear-gradient(
          to top,
          rgba(153, 223, 248, 0.7) 10%,
          rgba(235, 234, 234, 0.25) 50%,
          rgba(0, 0, 0, 0) 90%
        );
      }
      &.right::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -27px;
        left: 12%;
        width: 78%;
        height: 80px;
        transform: perspective(1.5em) rotateX(-12deg);
        animation: flash 3s linear infinite;
        background: linear-gradient(
          to top,
          rgba(153, 223, 248, 0.7) 10%,
          rgba(235, 234, 234, 0.25) 50%,
          rgba(0, 0, 0, 0) 90%
        );
      }
    }
  }
}
@keyframes flash {
  0% {
    background-size: 100% 100%;
  }
  25% {
    background-size: 100% 200%;
  }
  50% {
    background-size: 100% 500%;
  }
  75% {
    background-size: 100% 200%;
  }
  100% {
    background-size: 100% 100%;
  }
}
@keyframes titleTurn {
  0% {
    background-position: center 30%;
  }
  50% {
    background-position: center 70%;
  }
  100% {
    background-position: center 30%;
  }
}
@keyframes axisX {
  from {
    right: -27.5%;
    opacity: 0;
  }
  to {
    right: 27.5%;
    opacity: 1;
  }
}
.water-enter-active {
  animation: axisX 1s;
}
/* 结束 */
.water-leave-active {
  animation: axisX 1s reverse;
}
.login-out {
  position: fixed;
  right: 40px;
  top: 50px;
  cursor: pointer;
}
</style>
