import request from '@/utils/request'
const alarm = '/alarm-manager'
const digital = '/digital-family'
const dahua = '/dahua'
export const editCard = (subject) => {
  return request({
    url: 'http://service-9ro9kz71-1253858861.ap-shanghai.apigateway.myqcloud.com/release/get1DData',
    method: 'POST',
    data: { subject }
  })
}
// 获取预警详情
export const getAlarmDetail = (query) => {
  return request({
    url: `${alarm}/alarmDetail`,
    method: 'get',
    params: query
  })
}
// 获取预警列表
export const getAlarmList = (params) => {
  params.town = '莘庄镇'
  return request({
    url: `${digital}/alarmInfoXZ`,
    method: 'get',
    params: params
  })
}
//获取设备列表
export const deviceList = (params) => {
  return request({
    url: `${digital}/getXZGlobalDevice`,
    method: 'get',
    params: params
  })
}
//获取设备列表
export const deviceDHDetail = (params) => {
  return request({
    url: `${digital}/xzDevices/listAll`,
    method: 'get',
    params: params
  })
}
//获取大华视频实时流地址
export const realTimeAddress = (params) => {
  return request({
    url: `${dahua}/scene/admin/real-time-address`,
    // url: `http://10.7.2.71:9081/scene/admin/real-time-address`,
    method: 'post',
    data: params
  })
}
//语音播报
export const startTalk = (params) => {
  return request({
    url: `${dahua}/scene/admin/start-talk`,
    // url: `http://10.7.2.71:9081/scene/admin/start-talk`,
    method: 'post',
    data: params
  })
}
//获取设备列表--鹰眼
export const deviceXZYYList = () => {
  return request({
    url: `${digital}/getXZYYDevice`,
    method: 'get'
  })
}
//获取全球眼视频连接
export const getFlv = (params) => {
  return request({
    url: `${digital}/getXZGlobalDeviceVideo`,
    method: 'get',
    params: params
  })
}

//获取全球眼视频连接
export const alarmStatistic = (params) => {
  return request({
    url: `${digital}/smartStreet/alarmStatistic`,
    method: 'get',
    params: params
  })
}

/**
 * 街区智慧场景
 */
export const fetchStreetAlarmStat = () => {
  return request({
    url: `${digital}/smartStreet/alarmStatistic`,
    method: 'GET'
  })
}

/**
 * 街区智慧场景列表
 */
export const fetchStreetAlarmList = (alarmType, time, pageNumber = 1, pageSize = 10, deviceNo) => {
  return request({
    url: `${digital}/smartStreet/alarmList`,
    method: 'GET',
    params: { alarmType, time, pageNumber, pageSize, deviceNo }
  })
}

/**
 * 各报警类型占比
 */
export const fetchAlarmTypes = () => {
  return request({
    url: `${digital}/smartStreet/alarmProportion`,
    method: 'GET'
  })
}

/**
 * 各报警类型占比
 */
export const fetchAlarmTrend = (alarmType, time, timeType = 1) => {
  return request({
    url: `${digital}/smartStreet/alarmNumberTrend`,
    method: 'GET',
    params: { alarmType, time, timeType }
  })
}

/**
 * 重点关注类型情况
 */
export const fetchAlarmFocus = (alarmType, time) => {
  return request({
    url: `${digital}/smartStreet/alarmFocus`,
    method: 'GET',
    params: { alarmType, time }
  })
}

/**
 * 智能场景报警各维度分析
 */
export const fetchAlarmDimension = (alarmType, time) => {
  return request({
    url: `${digital}/smartStreet/alarmAnalysis`,
    method: 'GET',
    params: { alarmType, time }
  })
}
