<template>
  <svg aria-hidden="true" :style="{ '--color': color }">
    <use :href="symbolId" />
  </svg>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    title: {
      type: String,
      default: '无'
    },
    prefix: {
      type: String,
      default: 'icon'
    },
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#CEE6FF'
    }
  },
  computed: {
    symbolId() {
      return `#${this.prefix}-${this.name}`
    }
  }
}
</script>

<style scoped lang="stylus">
svg
  cursor pointer
  width 52px
  height 52px
  fill var(--color)
  transition 0.3s ease-in-out
  &:hover
    opacity 0.75
</style>
