import request from '@/utils/request'
const digital = '/digital-family'
const proxy = '/proxy'
const deploy = '/deploy-manager'
const electric = '/electric-card'
const backend = 'https://api.pre-saas.famesmart.com/pre/b-xinzhuang-unified/backend'
const video = 'http://test.saas.famesmart.com/xz'
//应急物资统计
export const countByEquType = (query) => {
  return request({
    url: `${backend}/enquirement/countByEquType`,
    method: 'get',
    params: query
  })
}
//应急物资列表
export const enquirementPageList = (query) => {
  return request({
    url: `${backend}/enquirement/pageList`,
    method: 'get',
    params: query
  })
}
//道路类型
export const xzRoadType = (query) => {
  return request({
    url: `${digital}/xzStreets/roadType`,
    method: 'get',
    params: query
  })
}

//绿道列表
export const xzGreenWaysList = (query) => {
  return request({
    url: `${digital}/xzGreenWays/pageList`,
    method: 'get',
    params: query
  })
}
//公园列表
export const xzParksList = (query) => {
  return request({
    url: `${digital}/xzParks/pageList`,
    method: 'get',
    params: query
  })
}
//河道列表
export const xzRiverCoursesList = (query) => {
  return request({
    url: `${digital}/xzRiverCourses/pageList`,
    method: 'get',
    params: query
  })
}
//商铺列表
export const xzShopsList = (query) => {
  return request({
    url: `${digital}/xzShops/pageList`,
    method: 'get',
    params: query
  })
}
//道路列表
export const xzRoadsCoursesList = (query) => {
  return request({
    url: `${digital}/xzStreets/pageList`,
    method: 'get',
    params: query
  })
}
//城运体征--图例撒点
export const xzPointsListAll = (query) => {
  return request({
    url: `${digital}/xzPoints/listAll`,
    method: 'get',
    params: query
  })
}
//人员列表
export const xzWorkersList = (query) => {
  return request({
    url: `${digital}/xzWorkers/pageList`,
    method: 'get',
    params: query
  })
}
//街区基本情况
export const streetInfo = (query) => {
  return request({
    url: `${digital}/xzScreen/street/basic/info`,
    method: 'get',
    params: query
  })
}
//街区服务力量
export const workerInfo = (query) => {
  return request({
    url: `${digital}/xzScreen/street/worker/stat`,
    method: 'get',
    params: query
  })
}
//社区--摄像头撒点
export const deviceCommList = (query) => {
  return request({
    url: `${deploy}/api/device`,
    method: 'get',
    params: query
  })
}
//电子工作证数量
export const cardTotalInfo = (query) => {
  return request({
    url: `${electric}/xz/card/totalInfo`,
    method: 'get',
    params: query
  })
}
//电子工作证列表
export const cardTotalList = (query) => {
  return request({
    url: `${electric}/xz/card/getDevices`,
    method: 'get',
    params: query
  })
}
export const fetchVideo = (query) => {
  return request({
    url: `${backend}/video/pageList`,
    method: 'get',
    params: query
  })
}
export const fetchBusGps = (query) => {
  return request({
    url: `${backend}/busGps/latestBusGuiJi`,
    method: 'get',
    params: query
  })
}
//车辆类型
export const listCarType = (query) => {
  return request({
    url: `${backend}/busGps/listCarType`,
    method: 'get',
    params: query
  })
}
//网格--按分类计数
export const xzGridType = (query) => {
  return request({
    url: `${digital}/xzGrids/type/count`,
    method: 'get',
    params: query
  })
}
//网格--列表
export const xzGridList = (query) => {
  return request({
    url: `${digital}/xzGrids/pageList`,
    method: 'get',
    params: query
  })
}
//网格--片区类型
export const xzGridArea = (query) => {
  return request({
    url: `${digital}/xzGrids/area`,
    method: 'get',
    params: query
  })
}
//视频--所有标签
export const xzTagsList = (query) => {
  return request({
    url: `${digital}/xzTags/listTags`,
    method: 'get',
    params: query
  })
}
//视频--所有路名
export const getXZGlobalDeviceLocs = (query) => {
  return request({
    url: `${digital}/getXZGlobalDeviceLocs`,
    method: 'get',
    params: query
  })
}
//公安视频--地址
export const videoPreview = (query) => {
  return request({
    url: `${backend}/video/preview`,
    method: 'get',
    params: query
  })
}
//值班领导
export const dutyList = (query) => {
  return request({
    url: `${backend}/duty/pageList`,
    method: 'get',
    params: query
  })
}
//街区智慧场景
export const alarmStatus = (query) => {
  return request({
    url: `${digital}/smartStreet/alarmStatus`,
    method: 'get',
    params: query
  })
}
//视频start接口
export const startVideo = (query, data) => {
  return request({
    url: `${proxy}/${query}/start`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
//视频stop接口
export const stopVideo = (query, data) => {
  return request({
    url: `${proxy}/${query}/stop`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
//视频start接口
export const startVideoHK = (data) => {
  return request({
    url: `${video}/rtsp/start`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
//视频stop接口
export const stopVideoHK = (data) => {
  return request({
    url: `${video}/rtsp/stop`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
//---------------------------------水务--------------------------------------------
//水务--设备数量统计
export const listGroupByDeviceType = () => {
  return request({
    url: `${backend}/swDeviceWater/listGroupByDeviceType`,
    method: 'get'
  })
}
//水务--设备数量统计--百分比
export const deviceAlarmPercent = () => {
  return request({
    url: `${backend}/swWaterCase/deviceAlarmPercent`,
    method: 'get'
  })
}
//水务--窨井水百分比位
export const waterLine = (query) => {
  return request({
    url: `${backend}/swWaterMinuteData/waterLine`,
    method: 'get',
    params: query
  })
}
//水务--撒点
export const swDeviceWaterList = (query) => {
  return request({
    url: `${backend}/swDeviceWater/listAll`,
    method: 'get',
    params: query
  })
}
//水务--报警列表
export const swWaterCaseList = (query) => {
  return request({
    url: `${backend}/swWaterCase/pageList`,
    method: 'get',
    params: query
  })
}
//水务--设备案件统计
export const swWaterCaseStatistics = (query) => {
  return request({
    url: `${backend}/swWaterCase/statistics`,
    method: 'get',
    params: query
  })
}
