<template>
  <div class="video-card full-bg">
    <el-dialog
      :title="titles"
      :visible.sync="videoDialog"
      width="1350px"
      custom-class="video-dialog"
      :show-close="true"
      append-to-body
      destroy-on-close>
      <div class="edge" />
      <div class="edge right-top" />
      <div class="edge left-bottom" />
      <div class="edge right-bottom" />
      <div class="actions">
        <el-tooltip effect="dark" content="截屏">
          <i @click="screenShot" class="el-icon-scissors" />
        </el-tooltip>
        <el-tooltip effect="dark" content="历史记录">
          <i @click="screenHistoryDialog = true" class="el-icon-folder-opened" />
        </el-tooltip>
      </div>
      <el-container
        v-loading="loadings"
        element-loading-custom-class="loading_color"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        element-loading-spinner="el-icon-loading"
        style="width: 100%; height: 749px">
        <video
          :class="{ filter: isShooting }"
          :id="videoId"
          style="width: 100%; height: 100%; object-fit: fill"
          autoplay
          controls
          muted></video>
      </el-container>
      <div class="video-dialog__btm full-bg">
        <div
          v-for="item in LEFT_BTNS"
          :key="`lybtn${item.name}`"
          class="item left"
          @click="changeButton(item)">
          <svg-icon :icon-class="item.icon" />
          <label>{{ item.name }}</label>
        </div>
        <div class="animate-button"><svg-icon :icon-class="activeIcon" /></div>
        <div
          v-for="item in RIGHT_BTNS"
          :key="`rybtn${item.name}`"
          class="item"
          @click="btnFunc(item)">
          <svg-icon :icon-class="item.icon" />
          <label>{{ item.name }}</label>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dictDialog"
      width="456px"
      custom-class="tags-dialog common-dialog"
      append-to-body>
      <div class="edge" />
      <div class="edge right-top" />
      <div class="edge left-bottom" />
      <div class="edge right-bottom" />
      <div slot="title" class="common-dialog__header">
        <LadderAnimate />
        <h3>自动播报词条库</h3>
        <LadderAnimate side="right" />
      </div>
      <h3 class="hint normal-text">
        可播报字段（仅支持单选）
        <i class="el-icon-edit cursor fr"></i>
      </h3>
      <div class="tags-wrap">
        <el-tag
          v-for="tag in DEFAULT_TAGS"
          :key="tag"
          :class="{ active: tag === activeTag }"
          @click="activeTag = tag">
          {{ tag }}
        </el-tag>
      </div>
      <div slot="footer" class="common-dialog__footer">
        <el-button @click="dictDialog = false" round>取消</el-button>
        <el-button @click="dictDialog = false" type="primary" round>确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="截图记录"
      :visible.sync="screenHistoryDialog"
      width="762px"
      custom-class="screen-record-diaolog common-dialog"
      :show-close="true"
      append-to-body>
      <div class="edge" />
      <div class="edge right-top" />
      <div class="edge left-bottom" />
      <div class="edge right-bottom" />
      <div slot="title" class="common-dialog__header">
        <LadderAnimate />
        <h3>截图记录</h3>
        <LadderAnimate side="right" />
      </div>
      <div class="time-select">
        <span>时间段:</span>
        <el-date-picker
          v-model="range"
          type="daterange"
          clearable="false"
          start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期" />
      </div>
      <div class="img-wrap">
        <div v-for="idx in 10" :key="`imgsc${idx}`" class="img-item">
          <img :src="require('@/assets/image/video.png')" />
          <span>2023-01-14 12:23:45</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import flvjs from 'flv.js'
import LadderAnimate from '@/components/AnimationCollection/LadderAnimate.vue'
import { getFlv } from '@/api/alarm'
const LEFT_BTNS = [
  {
    name: '关闭',
    icon: 'mic-mute'
  },
  {
    name: '喊话',
    icon: 'mic'
  }
]
const RIGHT_BTNS = [
  {
    name: '播报',
    icon: 'speaker'
  },
  {
    name: '词条库',
    icon: 'note'
  }
]
const DEFAULT_TAGS = [
  '请将非机动车听如规定区域内',
  '您已涉嫌占道经营',
  '禁止停车',
  '您已涉嫌跨门经营'
]

export default {
  components: {
    LadderAnimate
  },
  props: {
    videoDialogFlag: {
      type: Boolean,
      default: false
    },
    videoDialogData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      videoId: `videoId${Math.random().toString(36).substr(2)}`,
      range: [],
      activeTag: '禁止停车',
      DEFAULT_TAGS,
      LEFT_BTNS,
      RIGHT_BTNS,
      videoDialog: false,
      checked: false,
      dictDialog: false,
      activeIcon: 'mic',
      isShooting: false,
      screenHistoryDialog: false,
      loading: true,
      myVideo1: null,
      myVideo2: null,
      myVideo3: null,
      myVideo4: null,
      myVideo5: null,
      myVideo6: null,
      titles: '北门路东侧中国电信华为',
      myVideoD: null,
      loadings: true
    }
  },
  computed: {
    iconClass() {
      return this.checked ? 'checked' : 'uncheck'
    }
  },
  watch: {
    videoDialogFlag: {
      handler(newVal) {
        if (newVal) {
          this.zoomFunc()
        }
      },
      immediate: true,
      deep: true
    },
    videoDialog(val) {
      if (!val) {
        //关闭
        console.log('关闭')
        if (this.myVideoD) {
          this.myVideoD.pause()
          this.myVideoD.unload()
          this.myVideoD.detachMediaElement()
          this.myVideoD.destroy()
        }
        this.myVideoD = null
        this.$emit('closeDialog', false)
      }
    }
  },
  mounted() {},
  methods: {
    screenShot() {
      this.isShooting = true
      setTimeout(() => {
        this.isShooting = false
      }, 100)
    },
    btnFunc({ icon }) {
      switch (icon) {
        case 'note':
          this.dictDialog = true
          break
        case 'speaker':
          this.activeIcon = 'speaker'
          break
      }
    },
    async zoomFunc() {
      if (this.videoDialogData.channelId) {
        this.videoDialog = true
        this.loadings = true
        this.titles = this.videoDialogData.deviceName
        let res = await getFlv({
          channelId: this.videoDialogData.channelId,
          subType: 0,
          scheme: 'FLV_HTTP'
        })
        var videoD = document.getElementById(this.videoId)
        this.myVideoD = flvjs.createPlayer(
          {
            type: 'flv',
            isLive: true,
            hasAudio: false,
            url: res.data.url
          },
          {
            enableWorker: false, //不启用分离线程
            enableStashBuffer: false, //关闭IO隐藏缓冲区
            reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
            autoCleanupSourceBuffer: true //自动清除缓存
          }
        )
        this.myVideoD.attachMediaElement(videoD)
        this.myVideoD.load()
        this.myVideoD.play()
        this.loadings = false
      }
    },
    checkFunc() {
      this.$store.commit('updateCount')
      this.checked = !this.checked
    },
    changeButton(data) {
      this.activeIcon = data.icon
    },
    //视频播放
    async initFun(newVal) {
      let res = await getFlv({
        channelId: newVal.channelId,
        subType: 0,
        scheme: 'FLV_HTTP'
      })
      if (res.result == 0) {
        let num = this.videoIndex + 1
        var videoElement1 = document.getElementById(`videoElement${num}`)
        this[`myVideo${num}`] = flvjs.createPlayer(
          {
            type: 'flv',
            isLive: true,
            hasAudio: false,
            url: res.data.url
          },
          {
            enableWorker: false, //不启用分离线程
            enableStashBuffer: false, //关闭IO隐藏缓冲区
            reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
            autoCleanupSourceBuffer: true //自动清除缓存
          }
        )
        this[`myVideo${num}`].attachMediaElement(videoElement1)
        this[`myVideo${num}`].load()
        this[`myVideo${num}`].play()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.video-card {
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__badge {
    position: absolute;
    z-index: 1;
    background: rgba(9, 45, 65, 0.66);
    border-radius: 0 0 25px 0;
    padding: 3px 20px 3px 3px;
    display: flex;
    gap: 6px;
    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}
</style>

<style lang="less">
@import url('@/style/variables.less');
.el-dialog {
  padding: 10px;
  &.screen-record-diaolog {
    .img-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      margin-top: 24px;
      .img-item {
        display: grid;
        place-items: center;
        gap: 10px;
        img {
          width: 100%;
        }
        span {
          @normalText();
        }
      }
    }
    .time-select {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        @normalText();
      }
    }
    .el-date-editor {
      height: 32px;
      line-height: 32px !important;
      border-radius: unset;
      .el-range-separator {
        line-height: 32px;
      }
    }
  }
  &.tags-dialog {
    .hint {
      margin-bottom: 16px;
    }
    .tags-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .el-tag {
        font-size: 14px;
        @normalText();
        border-color: #51e3ff;
        cursor: pointer;
        &.active,
        &:hover {
          background: #409eff;
          background-image: unset;
          -webkit-text-fill-color: unset;
          color: #fff;
        }
      }
    }
    .common-dialog__footer {
      margin-top: 42px;
      button {
        padding: 8px 18px;
      }
      .el-button--default {
        background-color: unset;
        @normalText();
        border-color: rgba(153, 225, 250, 1);
      }
    }
  }
  &.video-dialog {
    background-color: unset;
    .el-dialog__headerbtn {
      right: 0;
      z-index: 1;
      transform: translate(75%, 25%);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      font-size: 24px;
      background: linear-gradient(to top, #51e3ff, #ade6fb);
      i {
        color: #fff;
        font-weight: bold;
        margin: auto;
      }
    }
    .edge {
      top: 54px;
      &.right-top {
        top: 54px;
      }
    }
    .actions {
      display: flex;
      gap: 8px;
      position: absolute;
      z-index: 1;
      background: rgba(9, 45, 65, 0.528);
      padding: 6px 24px 6px 6px;
      border-radius: 0 0 12px 0;
      i {
        font-size: 24px;
        cursor: pointer;
        background: linear-gradient(to top, #ade6fb 0%, #fff 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all 0.5s ease-in-out;
        &:hover {
          text-shadow: 0 0 1px #46bcff;
        }
      }
    }
    .video-dialog__btm {
      gap: 32px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      aspect-ratio: 1306 / 130;
      background-image: url('@/assets/svg/layout/bottom-dialog.svg');
      width: 100%;
      .animate-button {
        width: 100px;
        margin: 0 20px;
        transform: translate(-5%, -18%);
        svg {
          width: 52px;
          height: 52px;
        }
      }
      .item {
        display: grid;
        place-items: center;
        margin-bottom: 1.25%;
        cursor: pointer;
        opacity: 0.75;
        &:hover {
          opacity: 1;
        }
        &.left {
          svg {
            width: 21px;
            height: 21px;
            padding: 8px;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          padding: 10px;
          border-radius: 50%;
          background: rgba(0, 31, 36, 0.45);
        }
        label {
          font-size: 12px;
          background-image: linear-gradient(to top, rgba(253, 253, 253, 1), rgba(153, 225, 250, 1));
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .el-dialog__header {
      text-align: center;
      background-image: url('~@/assets/svg/dialog-header.svg');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
    }
    .el-dialog__body {
      box-shadow: 0 0 0 1px #51e3ff;
      padding: 0;
    }
    .el-dialog__title {
      background-image: linear-gradient(to top, rgba(253, 253, 253, 1), rgba(153, 225, 250, 1));
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    video {
      width: 100%;
    }
    .filter {
      filter: brightness(0.5);
    }
  }
}
</style>
