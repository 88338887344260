const state = {
  dialogFlagDic: '{}',
  dialogClickNum: 1
}
const mutations = {
  DIALOG_FLAG_DIC: (state, dialogFlagDic) => {
    state.dialogFlagDic = dialogFlagDic
  },
  DIALOG_CLICK_NUM: (state, dialogClickNum) => {
    state.dialogClickNum = dialogClickNum
  }
}
const actions = {
  dialogFlagDic(context, dialogFlagDic) {
    context.commit('DIALOG_FLAG_DIC', dialogFlagDic)
  },
  dialogClickNum(context, dialogClickNum) {
    context.commit('DIALOG_CLICK_NUM', dialogClickNum)
  }
}
export default { namespaced: true, state, mutations, actions }
