<template>
  <div class="ladder-wrap" :class="side">
    <div
      v-for="idx in num"
      :key="`ladder${idx}`"
      class="ladder"
      :class="`num${idx}`"
      :style="{ animationDelay: delayNum(idx) }" />
  </div>
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      default: 10
    },
    side: {
      type: String,
      default: 'left'
    }
  },
  computed: {
    minusNum() {
      return this.side === 'left' ? -1 : 0
    }
  },
  methods: {
    delayNum(idx) {
      if (this.side === 'left') {
        return `${1 - idx / 10}s`
      } else {
        return `${idx / 10}s`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ladder-wrap {
  display: flex;
  gap: 8px;
  .ladder {
    height: 20px;
    width: 10px;
    background-color: #fff;
    transform: skewX(-20deg);
    opacity: 0.1;
    animation: 2s shink2 ease infinite;
  }
  &.right {
    .ladder {
      transform: skewX(20deg);
    }
  }
  @keyframes shink2 {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
