export const POLYGONS = [
  {
    color: '#2b83f9',
    path: [
      [121.345057, 31.12566],
      [121.345969, 31.126092],
      [121.346307, 31.126161],
      [121.347068, 31.126565],
      [121.346999, 31.126872],
      [121.348195, 31.127483],
      [121.349128, 31.127933],
      [121.351435, 31.12909],
      [121.35313, 31.129899],
      [121.353699, 31.129449],
      [121.356982, 31.130927],
      [121.363655, 31.133765],
      [121.365779, 31.134564],
      [121.366241, 31.133838],
      [121.370511, 31.135877],
      [121.372185, 31.136125],
      [121.377388, 31.137053],
      [121.378139, 31.137117],
      [121.376294, 31.140432],
      [121.381165, 31.14101],
      [121.381326, 31.137585],
      [121.381497, 31.135069],
      [121.381712, 31.133995],
      [121.383461, 31.130845],
      [121.385767, 31.126473],
      [121.383841, 31.126229],
      [121.376825, 31.124521],
      [121.370184, 31.122849],
      [121.37337, 31.117963],
      [121.37616, 31.114004],
      [121.374808, 31.113297],
      [121.374507, 31.112911],
      [121.371857, 31.111607],
      [121.368521, 31.110403],
      [121.365635, 31.109779],
      [121.362287, 31.109025],
      [121.363628, 31.105672],
      [121.363628, 31.105672],
      [121.362926, 31.100887],
      [121.361048, 31.105112],
      [121.359347, 31.10835],
      [121.356917, 31.112691],
      [121.35357, 31.111341],
      [121.352411, 31.112719],
      [121.352014, 31.112544],
      [121.351864, 31.112801],
      [121.351371, 31.112618],
      [121.350995, 31.113077],
      [121.350609, 31.112948],
      [121.350416, 31.113518],
      [121.354289, 31.115428],
      [121.352175, 31.118606],
      [121.348753, 31.118138]
    ]
  },
  {
    color: '#ff8c00',
    path: [
      [121.385767, 31.126473],
      [121.383841, 31.126229],
      [121.376825, 31.124521],
      [121.370184, 31.122849],
      [121.37337, 31.117963],
      [121.37616, 31.114004],
      [121.378971, 31.109618],
      [121.379893, 31.1079],
      [121.381095, 31.105576],
      [121.384635, 31.098888],
      [121.386926, 31.094479],
      [121.394082, 31.096702],
      [121.400004, 31.098466],
      [121.404553, 31.099844],
      [121.407922, 31.100836],
      [121.406742, 31.10385],
      [121.405186, 31.10734],
      [121.406785, 31.108002],
      [121.405262, 31.111529],
      [121.403202, 31.110555],
      [121.399962, 31.109104],
      [121.397644, 31.11287],
      [121.397108, 31.113568],
      [121.3964, 31.113476],
      [121.395541, 31.114413],
      [121.393782, 31.117775],
      [121.39376, 31.118547],
      [121.393127, 31.119337],
      [121.395799, 31.123231],
      [121.399382, 31.126657],
      [121.397601, 31.127548],
      [121.396078, 31.12819],
      [121.394812, 31.128448],
      [121.393728, 31.12842],
      [121.389941, 31.127492]
    ]
  },
  {
    color: '#7cfc00',
    path: [
      [121.364621, 31.096174],
      [121.362926, 31.100887],
      [121.358967, 31.099757],
      [121.358344, 31.100823],
      [121.353538, 31.099775],
      [121.351263, 31.099362],
      [121.350437, 31.101649],
      [121.349246, 31.104856],
      [121.34842, 31.106886],
      [121.349611, 31.107244],
      [121.349536, 31.107694],
      [121.350319, 31.107648],
      [121.350437, 31.106711],
      [121.351843, 31.106812],
      [121.351725, 31.107795],
      [121.358537, 31.109439],
      [121.362926, 31.100887],
      [121.363628, 31.105672],
      [121.362287, 31.109025],
      [121.365635, 31.109779],
      [121.368521, 31.110403],
      [121.371857, 31.111607],
      [121.374507, 31.112911],
      [121.374808, 31.113297],
      [121.37616, 31.114004],
      [121.378971, 31.109618],
      [121.379893, 31.1079],
      [121.381095, 31.105576],
      [121.384635, 31.098888],
      [121.386926, 31.094479],
      [121.381798, 31.093009],
      [121.374051, 31.09074],
      [121.372517, 31.093808],
      [121.366445, 31.091838]
    ]
  },
  {
    color: '#ff4500',
    path: [
      [121.370184, 31.122849],
      [121.371794, 31.123309],
      [121.368544, 31.129692],
      [121.366494, 31.133807],
      [121.364209, 31.132659],
      [121.361377, 31.130932],
      [121.355197, 31.12825],
      [121.358362, 31.125229],
      [121.3598, 31.1233],
      [121.361109, 31.120444]
    ]
  }
]
